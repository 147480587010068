import React from 'react';
import { withTranslation } from 'react-i18next';
import './Services.css'
import Card from './Card';
import auditoria from '../img/redesenho/icons_services/auditoria.svg';
import broker from '../img/redesenho/icons_services/broker.svg';
import consultoria from '../img/redesenho/icons_services/consultoria.svg';


const Services = (props) => {
    const { t } = props;

    return (
        <div id="services" className="services-container">
            <div className="title-services">
                <span>{t("SERVICES")}</span>
            </div>
            <div className="services-card-container">
                <div className="help-card">
                    <Card img={auditoria} title={t("SERVICES-CARD1-TITLE")} subtitle={t("SERVICES-CARD1-SUBTITLE")} />
                </div>
                <div className="help-card" >
                    <Card img={consultoria} title={t("SERVICES-CARD2-TITLE")} subtitle={t("SERVICES-CARD2-SUBTITLE")} />
                </div>
                <div className="help-card" >
                    <Card img={broker} title={t("SERVICES-CARD3-TITLE")} subtitle={t("SERVICES-CARD3-SUBTITLE")} />
                </div>

            </div>
            
        </div>
    )
}


export default withTranslation()(Services);
