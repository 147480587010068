import React from 'react';
import { withTranslation } from 'react-i18next';
import "./MainServices.css"
import NavBar2 from "./NavBar2";
import i18n from '../i18n';

import viden from "../img/site_banner_en.m4v";
import vides from "../img/site_banner_es.m4v";
import vidpt from "../img/site_banner_pt.m4v";

const MainServices = (props) => {
    const { t } = props;

    return (
        <>
            {
                ((i18n.language === "en-GB") || (i18n.language !== "es-ES" && i18n.language !== "pt-PT" && i18n.language !== "en-GB")) && (
                    <div className="video-container">
                        <NavBar2 />
                        <video
                            width="100%" height="100%"
                            src={viden}
                            type="video/mp4"
                            loop
                            muted
                            playsInline
                            autoPlay={true}
                        />
                    </div>
                )
            }
            {
                i18n.language === "pt-PT" && (
                    <div className="video-container">
                        <NavBar2 />
                        <video
                            width="100%" height="100%"
                            src={vidpt}
                            type="video/mp4"
                            loop
                            muted
                            playsInline
                            autoPlay={true}
                        />
                    </div>
                )

            }

            {
                i18n.language === "es-ES" && (
                    <>
                        <div className="video-container">
                            <NavBar2 />
                            <video
                                width="100%" height="100%"
                                src={vides}
                                type="video/mp4"
                                loop
                                muted
                                playsInline
                                autoPlay={true}
                            />
                        </div>

                    </>
                )

            }
        </>

    );
}

export default withTranslation()(MainServices);
