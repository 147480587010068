import React from 'react';
import { withTranslation } from 'react-i18next';
import About2 from '../components/About2';
import Contactform from '../components/Contactform';
import Footer2 from '../components/Footer2';
import NavBar from '../components/NavBar';
import Newsletter from '../components/Newsletter';
import './HomePage.css'

const AboutPage = (props) => {
    const { t } = props;

    return (
        <>
        <NavBar t = {t} />
        <About2 t = {t} />
        <Newsletter t = {t} />
        <Contactform t = {t} />
        <Footer2 t = {t} />
        </>
    )
}


export default withTranslation()(AboutPage);
