import { withTranslation } from 'react-i18next';
import { useState } from 'react';
import './PopUpCircle.css';
import PopUpForm from './PopUpForm';
import { useDispatch, useSelector } from 'react-redux';
import { toggleContactPopup } from '../redux/contactPopup';

const PopUpCircle = () => {
    const dispatch = useDispatch();
    const { contactPopupOpen } = useSelector((state) => state.contactPopup);
    return (
        <div className='popup-container'>
            <div className={`popup-circle-container ${contactPopupOpen && 'hide-form-circle'}`} onClick={() => dispatch(toggleContactPopup("meeting"))}>
            </div>
            <PopUpForm />
        </div>
    )
}


export default withTranslation()(PopUpCircle);