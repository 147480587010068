import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import goals_spanish from '../src/img/goals_spanish.png';
import goals_english from '../src/img/goals_english.png';
import goals from '../src/img/2024_goals.png';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  'en-GB': {
    translation: {
      "img-goals": goals_english,
      "SUSTAINABLE-POLICY1": "Sustainability Policy in AgroInsider's Philosophy",
      "SUSTAINABLE-POLICY2": "Agroinsider, Ltd., places sustainability as a cornerstone of its existence and business model. Thus, it commits to adopting a sustainable business model by embracing the best environmental, circularity, and ethical practices throughout its value chain.",
      "SUSTAINABLE-POLICY3": "This includes, foremost, respect for the environment, circular economy, and ethics by adopting the best environmental practices suitable for our activity, including the use of digital tools for process optimization, such as telecommuting communication, knowledge sharing, and project monitoring.",
      "SUSTAINABLE-POLICY4": "Scope",
      "SUSTAINABLE-POLICY5": "Research, Development, and Innovation in the Data Treatment field for Agroforestry and Environmental sectors.",
      "SUSTAINABLE-POLICY6": "The entire organization is covered by sustainability objectives, with all employees aware of their responsibilities towards the company and its clients and suppliers.",
      "SUSTAINABLE-POLICY7": "AgroInsider's Sustainability Principles",
      "SUSTAINABLE-POLICY8": "In order to comply with this business model, it sets sustainability goals, including contributing to the decarbonization of its clients' economic activities and reducing their environmental footprint through consumption and emissions reduction, as well as promoting these practices among clients and suppliers.",
      "SUSTAINABLE-POLICY9": "Priority Objectives for Sustainable Development",
      "SUSTAINABLE-POLICY10": "It also highlights the main sustainable development objectives to which it believes it can and should contribute.",
      "SUSTAINABLE-POLICY11": "Commitments",
      "SUSTAINABLE-POLICY12": "It commits to developing projects for the reduction and/or compensation of agricultural carbon footprint with partners who respect the best ESG practices and to contribute to ecosystem restoration, biodiversity promotion, and respect for people.",
      "SUSTAINABLE-POLICY13": "It aims to achieve its environmental protection objectives directly by rationalizing consumption in its facilities, promoting rational use practices in the business center where it operates, and naturally through its consulting and business activities with clients and suppliers.",
      "SUSTAINABLE-POLICY14": "It commits to reducing material losses and waste, valuing the conservation and proper maintenance of its equipment to prolong their durability, in order to meet the overall objectives for the reuse, repair, renovation, and recycling of products and materials and contribute to environmental protection, integrating pollution prevention.",
      "SUSTAINABLE-POLICY15": "Specifically,",
      "SUSTAINABLE-POLICY16": "It commits to complying with legislation and best practices associated with sustainability.",
      "SUSTAINABLE-POLICY17": "It commits to continuously improving its sustainability performance by adopting or developing increasingly sustainable technologies, through monitoring the state of the art and new technologies to be adopted to achieve this end.",
      "SUSTAINABLE-POLICY18": "Policy Implementation",
      "SUSTAINABLE-POLICY19": "This policy will be communicated to employees during the beginning-of-year meeting or whenever reviewed. It is available in physical formats at the office entrance and digitally, in the Sustainability folder on Google Drive and on the company website.",
      "SUSTAINABLE-POLICY20": "Policy Approval and Review",
      "SUSTAINABLE-POLICY21": "It becomes effective after approval by the management and communication and will be accompanied by the sustainability officer, appointed annually by the management.",
      "SUSTAINABLE-POLICY22": "Policy Responsible",
      "SUSTAINABLE-POLICY23": "The management annually appoints the person responsible for the Sustainability Policy. This person must be familiar with DNPT4577-3, have knowledge in sustainability and digital transition.",
      "SUSTAINABLE-POLICY27": "The responsible person will be in charge of updating documents according to perceived needs or norm requirements; verifying compliance with sustainability commitments; scheduling and monitoring annual audits, as well as selecting auditors, according to criteria of impartiality and objectivity, as defined in the Audit Tool.",
      "SUSTAINABLE-POLICY24": "For the year 2024, Vanessa Duarte has been appointed as responsible.",
      "SUSTAINABLE-POLICY25": "Policy Non-Compliance",
      "SUSTAINABLE-POLICY26": "Non-compliance with the sustainability policy undermines AgroInsider's foundation and raison d'être.",
      "SUSTAINABILITY-POLICY": "Sustainability Policy",
      "CARBON_CREDITS": "CARBON CREDITS",
      "PRIVACY24": "By sending an email to info@agroinsider.com",
      "PRIVACY23": "How long do we keep your personal data?",
      "PRIVACY22": "“Cookies” are small text files that can be stored on your computer through the internet browser (browser), retaining only information related to your preferences, not including, as such, your personal data. They keep generic information, namely the way or place/country of access of the users and the way they use the sites, among others. Cookies only retain information related to your preferences.",
      "PRIVACY21": "How do we use “cookies”?",
      "PRIVACY20": "On the GDPR page",
      "PRIVACY19": "Upon written request to the email info@agroinder.com",
      "PRIVACY18": "Directly on the customer page (my account)",
      "PRIVACY17": "Without prejudice to the provisions of the RGPD, the holder of personal data may do so:",
      "PRIVACY16": "How can you access, rectify, update, limit, delete, oppose the processing of your personal data, or withdraw consent?",
      "PRIVACY15": "As holders of personal data, Customers/Users are guaranteed, at any time, the right to access, rectify, update, limit and erase their personal data (except when the data is indispensable for the provision of services and compliance with legal obligations ), the right to oppose their use for commercial purposes and to withdraw consent and the right to data portability.",
      "PRIVACY14": "What are the rights of the Client/User?",
      "PRIVACY13": "The period of time during which personal data is stored and maintained varies according to the purpose for which the information is processed. Safeguarding legal requirements, the data will be stored and kept only for the minimum period necessary, for the pursuit of the purposes that motivated its collection, or its subsequent processing, under the terms defined by law.",
      "PRIVACY12": "How long do we keep your personal data?",
      "PRIVACY11": "The data of our customers and Users are automatically stored on a secure server located in Portugal, being stored in specific databases created for this purpose. These servers are protected and maintained in accordance with the highest security standards and in compliance with applicable privacy laws. If you wish to be removed from our database, you can exercise this right by contacting us through the various means available on the website.",
      "PRIVACY10": "Where are User data stored?",
      "PRIVACY9": "Through the Client Area",
      "PRIVACY8": "If the holder of the personal data needs to contact the data controller, he can do so through the means and contacts indicated below:",
      "PRIVACY7": "Our company never discloses any personal data of its Customers and Users to third parties without their consent, except when required by law, and it is our responsibility to process the user's personal data.",
      "PRIVACY6": "Who has access to the User's personal data and who is responsible for data processing",
      "PRIVACY5": "The data collected in the forms on the website are exclusively intended for processing requests for information and contact from Customers/Users, namely data such as name, address, telephone number and email address and will not be used for any other purpose. processing. This information is under no circumstances provided to third parties, it is solely intended to serve you better. This will be exclusively used in the follow-up of negotiations/contacts made with you.",
      "PRIVACY4": "For what purposes are the User's personal data collected",
      "PRIVACY3": "Our company complies with and respects the Client/User's privacy and processes their personal data in accordance with the laws in force. The data will not be processed for any purposes other than those specified here, reserving the right to update or modify the Privacy Policy at any time, namely in order to adapt it to legislative changes.",
      "PRIVACY2": "Our website may include access links to other websites for which we are not responsible, and the User should consult their privacy policies.",
      "PRIVACY1": "This privacy policy covers this website. This Policy is intended to provide Customers/Users with knowledge of the general rules for the processing of personal data, which are collected and processed in strict respect and compliance with the provisions of the personal data protection legislation in force at any time, namely the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016.",
      "PRIVACY_POLICY": "Privacy Policy",
      "IDI-TEXT1": "AgroInsider has Sustainability at the root of its genesis, with Research, Development and Innovation activities being the basis of its creation, growth, and value.",
      "IDI-TEXT2": "AgroInsider is committed to contributing to sustainable processes in the Agroforestry and Environmental Economy through continuous monitoring of market and technology trends, fostering a culture of innovation and developing innovative solutions for analysis, monitoring, verification, and reporting.",
      "IDI-TEXT3": "AgroInsider assumes a strong commitment to Innovation based on a managed and operationalized strategy through an RDI Management System (SGIDI), implemented in accordance with the requirements of NP 4457: 2021.",
      "IDI-TEXT4": "AgroInsider is committed to ensuring the effectiveness of the SGIDI and its continuous improvement through the identification of opportunities and risks and assessments at planned intervals, in order to implement actions and assess their effectiveness.",
      "IDI-TEXT5": "Its RDI strategy is based on the following pillars:",
      "IDI-TEXT6": "Alignment of the RDI Strategy with the company's strategic pillars.",
      "IDI-TEXT7": "Fostering a creative, collaborative culture at the service of innovation.",
      "IDI-TEXT8": "Market knowledge through continuous interaction with customers and stakeholders, investing in co-creation processes, with the aim of promoting the adoption of new solutions.",
      "IDI-TEXT9": "Monitoring the state of the art of emerging technologies and establishing solid relationships with National and International RDI Ecosystems.",
      "IDI-TEXT10": "Membership in extended consortia for participation in European and national exploratory and innovation projects.",
      "IDI-TEXT11": "Valuing RDI's results by incorporating them into its Service Provision activities.",
      "IDI-TEXT12": "Significant improvements to AgroInsider's technology platforms in response to customer needs and market trends.",
      "IDI": "IDI Policy",
      "VISION-MISSION-TITLE": "Vision & Mission",
      "VISION-MISSION-SUBTITLE": "Contribute to the Sustainability of the Planet",
      "VISION": "Vision",
      "VISION-TEXT": "Promote global climate neutrality by contributing to the fulfillment of the European neutrality objectives for 2035 and 2050.",
      "MISSION": "Mission",
      "MISSION-TEXT": "Valuing the natural capital of agro-forestry companies by creating better practices in carbon balance management. From Earth observation data to produce intensive knowledge through monitoring, verification and reporting, making it socially relevant to decision-making in the management of natural capital.",
      "VALUES": "Values",
      "VALUES-TEXT": "Respect for nature and difference, Honesty, Spirit of sacrífice, Teamwork Resilience.",
      "ABOUT-US-TEXT-TWO": "We help companies being more efficient and reduce their footprint. Working with satellite data, we can check anywhere on Earth how forests and crops are evolving. We can then help industries track their imports and protect the environment.",
      "ABOUT-US-TEXT-ONE": "We are fuelled by a renewable energy: our love for people and the planet.",
      "WHO-ARE-WE": "Who are we and what moves us?",
      "NAVBAR-ABOUTUS": "About us",
      "SEND": "SEND",
      "PHONENUMBER": "Phone number",
      "MESSAGE": "Message",
      "EMAIL": "Email",
      "NEWS": "News",
      "SUBJECT": "Subject",
      "ERROR": "Error",
      "INFO": "Info",
      "SUCCESS": "Success",
      "THANK_CONTACT": "Thank you for contact!",
      "EMAIL_INVALID": "Email is not valid",
      "FILL_ALL_FIELDS": "Fill all fields",
      "ERROR_SUBMITING_FORM": "Error submiting form, try again",
      "INVALID_DATE": "Invalid date, choose another date",
      "AT": "at",
      "MEETING_SCHEDULED_FOR": "Meeting scheduled for",
      "OTHER": "OTHER",
      "SCHEDULE_CALL": "SCHEDULE CALL",
      "HERE": "here",
      "PERSONAL_DATA": "The personal data collected will be treated according to the terms defined in the privacy policy, available",
      "DATE": "Date",
      "CALL_DETAILS": "Please enter your details and choose a date for us to contact you.",
      "WANT_TO_SCHEDULE_A_CALL": "WANT TO SCHEDULE A CALL?",
      "COUNTRY": "Country",
      "FILL-ALL-FIELDS": "Fill all fields",
      "ERROR-SUBMITING-FORM": "Error submiting form",
      "INVALID-DATE": "Invalid Date, choose another date",
      "AT": "at",
      "MEETING-SCHEDULED-FOR": "Meeting scheduled for",
      "HERE": "here",
      "PERSONAL-DATA": "The personal data collected will be treated according to the terms defined in the privacy policy, available",
      "SCHEDULE-CALL": "SCHEDULE CALL",
      "DATE": "Date",
      "ENTER-NUMBER": "Enter your number",
      "NAME": "Name",
      "PLEASE-MEETING": "Please enter your details and choose a date for us to contact you.",
      "WANT-SHEDULE-MEETING": "WANT TO SCHEDULE A CALL?",
      "THANK-YOU": "Thank you",
      "NAVBAR-HOME": "Home",
      "NAVBAR-ABOUT": "About us",
      "NAVBAR-SERVICES": "Services",
      "NAVBAR-NEWS": "News",
      "NAVBAR-APPS": "Apps",
      "NAVBAR-BLOG": "Blog",
      "NAVBAR-CONTACTUS": "Contact us",
      "FOLLOW": "Follow us: ",

      "ENTER-EMAIL": "Enter your email here",

      "NEWSLETTER": "Subscribe to our newsletter",
      "NEWSLETTER-SUBSCRIBE": "SUBSCRIBE",


      "ABOUTUS-TITLE": "About Us",
      "ABOUTUS-ENVIRONMENTAL-TITLE": "Environmental and agricultural sustainability",
      "ABOUTUS-ENVIRONMENTAL-TEXT": "We work with the purpose of promoting environmental sustainability so that agriculture becomes more positive. A carbon-neutral world generates positive agriculture.",
      "ABOUTUS-CARBON-TITLE": "Carbon Market Experts",
      "ABOUTUS-CARBON-TEXT-ONE": "A company specialized and dedicated to the carbon market.",
      "ABOUTUS-CARBON-TEXT-TWO": "We develop carbon quantification projects on your farm.",

      "ABOUTUS-KNOW-MORE": "TO KNOW MORE",
      "ABOUTUS-INNOVATION-TITLE": "Innovation for your Business",
      "ABOUTUS-INNOVATION-TEXT": "We innovate your business with our most advanced technologies. We unite satellite technology with the convenience of a mobile application.",
      "ABOUTUS-DISCOVER-APPS": "DISCOVER THE APP’S",

      "SERVICES": "Services",
      "SERVICES-CARD1-TITLE": "Diagnostic",
      "SERVICES-CARD1-SUBTITLE": "Farm Diagnosis. We analyze the activities performed on your farm, quantify them with the Agroinsider methodologies and issue a certificate with your environmental footprint, assigning amonetary value capable of being marketed.",

      "SERVICES-CARD2-TITLE": "Consulting",
      "SERVICES-CARD2-SUBTITLE": "We analyze and ensure the best quality of the entire cultivation process. We indicate best practices for greater carbon sequestration on your farm.",

      "SERVICES-CARD3-TITLE": "Broker",
      "SERVICES-CARD3-SUBTITLE": "We help you buy and sell your carbon credits in the international market.",

      "CONTACTUS-TITLE": "Contact Us",
      "CONTACTUS-SUBTITLE": "Be part of the future with us. Save money and time.",

      "CARBON-TITLE": "Carbon Solutions",

      "CARBON-CARD1-TITLE": "Smart farming",
      "CARBON-CARD1-SUBTITLE": "Greater agricultural efficiency leads to greater carbon efficiency.",


      "CARBON-CARD2-TITLE": "Biodiversity",
      "CARBON-CARD2-SUBTITLE": "Plan to use uncultivated areas for carbon sequestration.",

      "CARBON-CARD3-TITLE": "Carbon credits",
      "CARBON-CARD3-SUBTITLE": "Quantification of carbon credits, validation, registration and commercialization.",

      "APPS-WEBAPPS": "Agroinsider Web Apps",
      "APPS-WEBAPPS-TEXT": "By combining agricultural management and data captured using satellite technologies, Agroinsider offers unique platforms for producers to promote efficient decisions.",
      "APPS-GET-STARTED": "GET STARTED",

      "APPS-FEATURES": "Features",
      "APPS-FEATURES-ICON1-TITLE": "Weekly reports",
      "APPS-FEATURES-ICON1-TEXT": "Weekly reports according to the status of your operation. With color variations (green, yellow or red) according to the greatest variability.",

      "APPS-FEATURES-ICON2-TITLE": "Creation of management areas",
      "APPS-FEATURES-ICON2-TEXT": "Management maps according to the zones that present distinct variability in their plot. Ease at the time of introducing inputs in a sustainable way.",

      "APP-FEATURES-TITLE": "AgroMap and SmartAg alert system!",

      "APP-ALERTS": "Alerts",

      "APPS-FEATURES-CARD1-TITLE": "Irrigation systems",
      "APPS-FEATURES-CARD1-SUBTITLE": "We optimize your water use and detect possible problems in the irrigation system.",

      "APPS-FEATURES-CARD2-TITLE": "Productivity deviation",
      "APPS-FEATURES-CARD2-SUBTITLE": "Real-time monitoring of your crop's vegetative development.",

      "APPS-FEATURES-CARD3-TITLE": "Anomalies",
      "APPS-FEATURES-CARD3-SUBTITLE": "According to our algorithms, yellow or red alerts are generated according to the variability of the plot.",
      "PROJECTS": "Projects",
      "CLIENT-AREA-TITLE": "Client Area",
      "START": "ENTER",

    }
  },


  'es-ES': {
    translation: {
      "img-goals": goals_spanish,
      "SUSTAINABLE-POLICY1": "Política de Sostenibilidad en la Filosofía de AgroInsider",
      "SUSTAINABLE-POLICY2": "Agroinsider, S.L., tiene la sostenibilidad como punto fundamental de su existencia y modelo de negocio. Por lo tanto, se compromete a adoptar un modelo de negocio sostenible, abrazando las mejores prácticas ambientales, de circularidad y éticas a lo largo de toda su cadena de valor.",
      "SUSTAINABLE-POLICY3": "Esto incluye, en primer lugar, el respeto por el medio ambiente, la economía circular y la ética mediante la adopción de las mejores prácticas ambientales adecuadas para nuestra actividad, incluido el uso de herramientas digitales para la optimización de procesos, como la comunicación en teletrabajo, el intercambio de conocimientos y el monitoreo de proyectos.",
      "SUSTAINABLE-POLICY4": "Ámbito",
      "SUSTAINABLE-POLICY5": "Investigación, Desarrollo e Innovación en el área del Tratamiento de Datos para los sectores Agroforestal y Ambiental.",
      "SUSTAINABLE-POLICY6": "Toda la organización está cubierta por objetivos de sostenibilidad, con todos los empleados conscientes de sus responsabilidades hacia la empresa y sus clientes y proveedores.",
      "SUSTAINABLE-POLICY7": "Principios de Sostenibilidad de AgroInsider",
      "SUSTAINABLE-POLICY8": "Con el fin de cumplir con este modelo de negocio, establece objetivos de sostenibilidad, incluida la contribución a la descarbonización de las actividades económicas de sus clientes y la reducción de su huella ambiental mediante la reducción de consumo y emisiones, así como la promoción de estas prácticas entre clientes y proveedores.",
      "SUSTAINABLE-POLICY9": "Objetivos Prioritarios para el Desarrollo Sostenible",
      "SUSTAINABLE-POLICY10": "También destaca los principales objetivos de desarrollo sostenible a los cuales cree que puede y debe contribuir.",
      "SUSTAINABLE-POLICY11": "Compromisos",
      "SUSTAINABLE-POLICY12": "Se compromete a desarrollar proyectos para la reducción y/o compensación de la huella de carbono agrícola con socios que respeten las mejores prácticas ESG y a contribuir a la restauración del ecosistema, la promoción de la biodiversidad y el respeto por las personas.",
      "SUSTAINABLE-POLICY13": "Busca alcanzar sus objetivos de protección ambiental directamente mediante la racionalización del consumo en sus instalaciones, la promoción de prácticas de uso racional en el centro empresarial donde opera y, naturalmente, a través de sus actividades de consultoría y negocios con clientes y proveedores.",
      "SUSTAINABLE-POLICY14": "Se compromete a reducir pérdidas y desperdicios de materiales, valorar la conservación y el mantenimiento adecuado de sus equipos para prolongar su durabilidad, con el fin de cumplir con los objetivos generales de reutilización, reparación, renovación y reciclaje de productos y materiales y contribuir a la protección del medio ambiente, integrando la prevención de la contaminación.",
      "SUSTAINABLE-POLICY15": "En concreto,",
      "SUSTAINABLE-POLICY16": "Se compromete a cumplir con la legislación y las mejores prácticas asociadas con la sostenibilidad.",
      "SUSTAINABLE-POLICY17": "Se compromete a mejorar continuamente su desempeño de sostenibilidad mediante la adopción o desarrollo de tecnologías cada vez más sostenibles, a través del monitoreo del estado del arte y nuevas tecnologías para ser adoptadas para lograr este fin.",
      "SUSTAINABLE-POLICY18": "Implementación de la Política",
      "SUSTAINABLE-POLICY19": "Esta política será comunicada a los colaboradores durante la reunión de inicio de año o cada vez que sea revisada. Está disponible en formatos físicos en la entrada de la oficina y digitalmente, en la carpeta de Sostenibilidad en Google Drive y en el sitio web de la empresa.",
      "SUSTAINABLE-POLICY20": "Aprobación y Revisión de la Política",
      "SUSTAINABLE-POLICY21": "Entra en vigencia después de la aprobación por la dirección y comunicación, y será acompañada por el responsable de sostenibilidad, designado anualmente por la dirección.",
      "SUSTAINABLE-POLICY22": "Responsable de la Política",
      "SUSTAINABLE-POLICY23": "La dirección designa anualmente a la persona responsable de la Política de Sostenibilidad. Esta persona debe estar familiarizada con la DNPT4577-3, tener conocimientos en sostenibilidad y transición digital.",
      "SUSTAINABLE-POLICY27": "La persona responsable estará a cargo de la actualización de documentos según necesidades percibidas o requisitos de la norma; verificar el cumplimiento de los compromisos de sostenibilidad; programar y monitorear auditorías anuales, así como seleccionar auditores, según criterios de imparcialidad y objetividad, como se define en la Herramienta de Auditoría.",
      "SUSTAINABLE-POLICY24": "Para el año 2024, se ha designado a Vanessa Duarte como responsable.",
      "SUSTAINABLE-POLICY25": "Incumplimiento de la Política",
      "SUSTAINABLE-POLICY26": "El incumplimiento de la política de sostenibilidad socava los fundamentos y la razón de ser de AgroInsider.",
      "SUSTAINABILITY-POLICY": "Política de sostenibilidad",
      "CARBON_CREDITS": "CRÉDITOS DE CARBONO",
      "PRIVACY24": "Enviando un correo electrónico a info@agroinsider.com",
      "PRIVACY23": "¿Cuánto tiempo conservamos sus datos personales?",
      "PRIVACY22": "Las “cookies” son pequeños archivos de texto que pueden almacenarse en su computadora a través del navegador de Internet (navegador), reteniendo únicamente información relacionada con sus preferencias, sin incluir, como tal, sus datos personales. Almacenan información genérica, a saber, la forma o lugar/país de acceso de los usuarios y cómo utilizan los sitios, entre otros. Las cookies solo retienen información relacionada con sus preferencias.",
      "PRIVACY21": "Cómo usamos las cookies",
      "PRIVACY20": "En la página del RGPD",
      "PRIVACY19": "Previa solicitud por escrito al correo electrónico info@agroinder.com",
      "PRIVACY18": "Directamente en la página del cliente (mi cuenta)",
      "PRIVACY17": "Sin perjuicio de lo dispuesto en el RGPD, el titular de los datos personales podrá hacerlo:",
      "PRIVACY16": "¿Cómo puede acceder, rectificar, actualizar, limitar, suprimir, oponerse al tratamiento de sus datos personales o retirar el consentimiento?",
      "PRIVACY15": "Como titulares de datos personales, los Clientes/Usuarios tienen garantizados, en todo momento, los derechos de acceso, rectificación, actualización, limitación y supresión de sus datos personales (excepto cuando los datos sean indispensables para la prestación de servicios y el cumplimiento de obligaciones legales), el derecho a oponerse a su uso con fines comerciales y a retirar el consentimiento y el derecho a la portabilidad de los datos.",
      "PRIVACY14": "¿Cuáles son los derechos del Cliente/Usuario?",
      "PRIVACY13": "El período de tiempo durante el cual se almacenan y mantienen los datos personales varía según el propósito para el cual se procesa la información. En salvaguarda de los requisitos legales, los datos serán almacenados y conservados únicamente durante el período mínimo necesario, para la consecución de los fines que motivaron su recogida, o su posterior tratamiento, en los términos definidos por la ley.",
      "PRIVACY12": "¿Cuánto tiempo conservamos sus datos personales?",
      "PRIVACY11": "Los datos de nuestros clientes y Usuarios son almacenados automáticamente en un servidor seguro ubicado en Portugal, siendo almacenados en bases de datos específicas creadas a tal efecto. Estos servidores están protegidos y mantenidos de acuerdo con los más altos estándares de seguridad y en cumplimiento de las leyes de privacidad aplicables. Si desea ser eliminado de nuestra base de datos, puede ejercer este derecho poniéndose en contacto con nosotros a través de los distintos medios disponibles en el sitio web.",
      "PRIVACY10": "¿Dónde se almacenan los datos del Usuario?",
      "PRIVACY9": "A través del Área de Cliente",
      "PRIVACY8": "Si el titular de los datos personales necesita contactar al responsable del tratamiento, puede hacerlo a través de los medios y contactos que se indican a continuación:",
      "PRIVACY7": "Nuestra empresa nunca divulga ningún dato personal de sus Clientes y Usuarios a terceros sin su consentimiento, excepto cuando así lo exija la ley, y es nuestra responsabilidad tratar los datos personales del usuario.",
      "PRIVACY6": "Quién tiene acceso a los datos personales del Usuario y quién es el responsable del tratamiento de los datos",
      "PRIVACY5": "Los datos recogidos en los formularios del sitio web están exclusivamente destinados a la tramitación de las solicitudes de información y contacto de los Clientes/Usuarios, concretamente datos como nombre, dirección, número de teléfono y dirección de correo electrónico y no serán utilizados para ningún otro tratamiento. Esta información no se proporciona bajo ninguna circunstancia a terceros, su única intención es servirle mejor. Este será utilizado exclusivamente en el seguimiento de negociaciones/contactos realizados con usted.",
      "PRIVACY4": "¿Con qué finalidades se recogen los datos personales del Usuario?",
      "PRIVACY3": "Nuestra empresa cumple y respeta la privacidad del Cliente/Usuario y trata sus datos personales de acuerdo con las leyes vigentes. Los datos no serán tratados para finalidades distintas de las aquí especificadas, reservándose el derecho a actualizar o modificar la Política de Privacidad en cualquier momento, en particular, para adaptarla a los cambios legislativos.",
      "PRIVACY2": "Nuestro sitio web puede incluir enlaces de acceso a otros sitios web de los que no somos responsables y el Usuario deberá consultar sus políticas de privacidad.",
      "PRIVACY1": "Esta política de privacidad cubre este sitio web. La presente Política tiene por objeto proporcionar a los Clientes/Usuarios el conocimiento de las normas generales para el tratamiento de datos personales, que son recabados y tratados con estricto respeto y cumplimiento de lo dispuesto en la legislación de protección de datos personales vigente en cada momento, a saber, el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 (“GDPR”).",
      "PRIVACY_POLICY": "Política de Privacidad",
      "IDI-TEXT1": "AgroInsider tiene la Sostenibilidad en la raíz de su génesis, siendo las actividades de Investigación, Desarrollo e Innovación la base de su creación, crecimiento y potenciación.",
      "IDI-TEXT2": "AgroInsider se compromete a contribuir a procesos sostenibles en la Economía Agroforestal y Ambiental a través del monitoreo continuo de las tendencias tecnológicas y del mercado, fomentando una cultura de innovación y desarrollando soluciones innovadoras para análisis, monitoreo, verificación y reporte.",
      "IDI-TEXT3": "AgroInsider asume una fuerte apuesta por la Innovación basada en una estrategia gestionada y operacionalizada a través de un Sistema de Gestión de I+D+i (SGIDI), implantado de acuerdo con los requisitos de la NP 4457:2021",
      "IDI-TEXT4": "AgroInsider asume el compromiso de garantizar la eficacia del SGIDI y su mejora continua mediante la identificación de oportunidades y riesgos, y evaluaciones en intervalos planificados, con el fin de implementar acciones y evaluar la eficacia de estas.",
      "IDI-TEXT5": "Su estrategia de I+D+i se basa en los siguientes pilares:",
      "IDI-TEXT6": "Alineación de la Estrategia de I+D+i con los pilares estratégicos de la compañía.",
      "IDI-TEXT7": "Fomentar una cultura creativa y colaborativa al servicio de la innovación.",
      "IDI-TEXT8": "Conocimiento del mercado a través de la interacción continua con clientes y partes interesadas, invirtiendo en procesos de co-creación, con el objetivo de promover la adopción de nuevas soluciones.",
      "IDI-TEXT9": "Seguimiento del estado del arte de las tecnologías emergentes y establecimiento de sólidas relaciones con los Ecosistemas de I+D+i Nacionales e Internacionales.",
      "IDI-TEXT10": "Pertenencia a consorcios ampliados para la participación en proyectos exploratorios y de innovación europeos y nacionales.",
      "IDI-TEXT11": "Valorar los resultados de RDI incorporándolos a sus actividades de Prestación de Servicios.",
      "IDI-TEXT12": "Mejoras significativas en las plataformas tecnológicas de AgroInsider en respuesta a las necesidades de los clientes y las tendencias del mercado.",
      "IDI": "Política de IDI",
      "VISION-MISSION-TITLE": "Visión y Misión",
      "VISION-MISSION-SUBTITLE": "Contribuir a la Sostenibilidad del Planeta",
      "VISION": "Visión",
      "VISION-TEXT": "Promover la neutralidad climática global contribuyendo al cumplimiento de los objetivos europeos de neutralidad para 2035 y 2050.",
      "MISSION": "Misión",
      "MISSION-TEXT": "Valorización del capital natural de las empresas agroforestales mediante la creación de mejores prácticas en la gestión del balance de carbono a partir de datos de observación de la Tierra para producir conocimiento intensivo a través del monitoreo, verificación y reporte, haciéndolo socialmente relevante para la toma de decisiones en la gestión del capital natural.",
      "VALUES": "Valores",
      "VALUES-TEXT": "Respeto por la naturaleza y la diferencia honestidad, espíritu de sacrificio, trabajo en equipo Resiliencia",
      "ABOUT-US-TEXT-TWO": "Ayudamos a las empresas a ser más eficientes y reducir su huella. Trabajando con datos de satélite, podemos comprobar en cualquier lugar de la Tierra cómo evolucionan los bosques y los cultivos. Así podemos ayudar a las industrias a hacer un seguimiento de sus importaciones y proteger el medio ambiente.",
      "ABOUT-US-TEXT-ONE": "Nos alimenta una energía renovable: nuestro amor por las personas y el planeta.",
      "WHO-ARE-WE": "¿Quiénes somos y qué nos mueve?",
      "NAVBAR-ABOUTUS": "Sobre nosotros",
      "SEND": "ENVIAR",
      "PHONENUMBER": "Número de teléfono",
      "MESSAGE": "Mensaje",
      "EMAIL": "Email",
      "NEWS": "Noticias",
      "ERROR": "Erro",
      "INFO": "Información",
      "SUCCESS": "Éxito",
      "THANK_CONTACT": "Gracias por el contacto!",
      "EMAIL_INVALID": "El correo electrónico no es válido",
      "FILL_ALL_FIELDS": "Rellenar todos los campos",
      "ERROR_SUBMITING_FORM": "Error al enviar el formulario, inténtelo de nuevo",
      "INVALID_DATE": "Fecha no válida, seleccione otra fecha",
      "AT": "en",
      "MEETING_SCHEDULED_FOR": "Reunión prevista para",
      "OTHER": "OTRO",
      "SCHEDULE_CALL": "PROGRAMAR LLAMADA",
      "HERE": "aquí",
      "PERSONAL_DATA": "Los datos personales recogidos serán tratados de acuerdo con los términos definidos en la política de privacidad, disponible en",
      "DATE": "Fecha",
      "CALL_DETAILS": "Introduzca sus datos y elija una fecha para que nos pongamos en contacto con usted.",
      "WANT_TO_SCHEDULE_A_CALL": "QUIERE CONCERTAR UNA LLAMADA?",
      "COUNTRY": "País",
      "SUBJECT": "Asunto",
      "FILL-ALL-FIELDS": "Rellene todos los campos",
      "ERROR-SUBMITING-FORM": "Error al enviar el formulario",
      "INVALID-DATE": "Fecha no válida, elija otra fecha",
      "AT": "en",
      "MEETING-SCHEDULED-FOR": "Reunión prevista para",
      "HERE": "aquí",
      "PERSONAL-DATA": "Los datos personales recogidos serán tratados según los términos definidos en la política de privacidad, disponible",
      "SCHEDULE-CALL": "PROGRAMAR LLAMADA",
      "DATE": "Fecha",
      "ENTER-NUMBER": "Introduzca su número",
      "NAME": "Nombre",
      "PLEASE-MEETING": "Por favor, introduzca sus datos y elija una fecha para que nos pongamos en contacto con usted",
      "WANT-SHEDULE-MEETING": "QUIERES PROGRAMAR UNA LLAMADA?",
      "THANK-YOU": "Gracias",
      "NAVBAR-HOME": "Inicio",
      "NAVBAR-ABOUT": "Acerca de nosotros",
      "NAVBAR-SERVICES": "Servicios",
      "NAVBAR-NEWS": "Noticias",
      "NAVBAR-APPS": "Apps",
      "NAVBAR-BLOG": "Blog",
      "NAVBAR-CONTACTUS": "Contacto",
      "FOLLOW": "Siga con nosotros: ",

      "ENTER-EMAIL": "Introduzca su correo electrónico aquí",

      "NEWSLETTER": "Suscríbase a nuestra newsletter",
      "NEWSLETTER-SUBSCRIBE": "SUSCRÍBASE",


      "ABOUTUS-TITLE": "Acerca de nosotros",
      "ABOUTUS-ENVIRONMENTAL-TITLE": "Sostenibilidad Ambiental y Agrícola",
      "ABOUTUS-ENVIRONMENTAL-TEXT": "Trabajamos con el propósito de promover la sostenibilidad ambiental para que la agricultura se vuelva más positiva. Un mundo carbono neutral genera una agricultura positiva.",
      "ABOUTUS-CARBON-TITLE": "Expertos en el Mercado de Carbono",
      "ABOUTUS-CARBON-TEXT-ONE": "Empresa especializada y dedicada al mercado del carbono.",
      "ABOUTUS-CARBON-TEXT-TWO": "Desarrollamos proyectos de cuantificación de carbono en su explotación agraria.",

      "ABOUTUS-KNOW-MORE": "SABER MÁS",
      "ABOUTUS-INNOVATION-TITLE": "Innovación para su Negocio",
      "ABOUTUS-INNOVATION-TEXT": "Innovamos su negocio con nuestras tecnologías más avanzadas. Combinamos la tecnología de satélite con una práctica aplicación móvil.",
      "ABOUTUS-DISCOVER-APPS": "DESCUBRE LAS APP'S",

      "SERVICES": "Servicios",
      "SERVICES-CARD1-TITLE": "Auditoría",
      "SERVICES-CARD1-SUBTITLE": "Analizamos las actividades realizadas en su explotación, cuantificamos y emitimos un certificado con su marca ambiental, atribuyéndole un valor monetario a ser comercializado.",

      "SERVICES-CARD2-TITLE": "Consultoría",
      "SERVICES-CARD2-SUBTITLE": "Gestionamos y aseguramos la mejor calidad de todo el proceso productivo. Indicamos buenas prácticas para el mayor secuestro de carbono en su explotación.",

      "SERVICES-CARD3-TITLE": "Broker",
      "SERVICES-CARD3-SUBTITLE": "Asistimos en la compra y venta de sus créditos de carbono en el mercado internacional.",

      "CONTACTUS-TITLE": "Contacto",
      "CONTACTUS-SUBTITLE": "Se parte del futuro con nosotros. Ahorrar tiempo y dinero.",

      "CARBON-TITLE": "Soluciones  de Carbono",

      "CARBON-CARD1-TITLE": "Agricultura Inteligente",
      "CARBON-CARD1-SUBTITLE": "Mayor eficiencia agrícola genera una mayor eficiencia de carbono.",


      "CARBON-CARD2-TITLE": "Biodiversidad",
      "CARBON-CARD2-SUBTITLE": "Planificar el uso de áreas no cultivadas para el secuestro de carbono.",

      "CARBON-CARD3-TITLE": "Créditos CO₂",
      "CARBON-CARD3-SUBTITLE": "Cuantificación de créditos de carbono, validación, registro y comercialización.",

      "APPS-WEBAPPS": "Agroinsider Web Apps",
      "APPS-WEBAPPS-TEXT": "Con la combinación de gestión agrícola y datos capturados utilizando tecnologías satelitales, Agroinsider ofrece plataformas y aplicaciones únicas para los productores, de modo a promover decisiones eficientes.",
      "APPS-GET-STARTED": "EMPEZAR AGORA",

      "APPS-FEATURES": "Funciones",
      "APPS-FEATURES-ICON1-TITLE": "Reportes semanales",
      "APPS-FEATURES-ICON1-TEXT": "Reportes semanales según el estado de tu explotación. Con variaciones de color (verde, amarillo o rojo) según mayor variabilidad.",

      "APPS-FEATURES-ICON2-TITLE": "Creación de zonas de manejo",
      "APPS-FEATURES-ICON2-TEXT": "Mapas de gestión de acuerdo con las zonas que tienen diferentes características en su parcela. Facilidad para utilizar insumos de forma sostenible.",

      "APP-FEATURES-TITLE": "¡El sistema de alertas Agromap y Smartag!",

      "APP-ALERTS": "Alertas",

      "APPS-FEATURES-CARD1-TITLE": "Sistemas de riego",
      "APPS-FEATURES-CARD1-SUBTITLE": "Optimizamos su consumo de agua y detectamos posibles problemas en el sistema de riego.",

      "APPS-FEATURES-CARD2-TITLE": "Desviación de produtividad",
      "APPS-FEATURES-CARD2-SUBTITLE": "Monitoreo en tiempo real del desarrollo vegetativo de su cultivo.",

      "APPS-FEATURES-CARD3-TITLE": "Anomalías",
      "APPS-FEATURES-CARD3-SUBTITLE": "Según nuestros algoritmos se generan alertas amarillas o rojas según la variabilidad de la parcela.",
      "PROJECTS": "Proyectos",

      "CLIENT-AREA-TITLE": "Area de Cliente",
      "START": "INICIE",
    }
  },

  'pt-PT': {
    translation: {
      "img-goals": goals,
      "SUSTAINABLE-POLICY1": "A Política de Sustentabilidade na Filosofia da AgroInsider",
      "SUSTAINABLE-POLICY2": "A Agroinsider, Lda., tem a sustentabilidade como ponto fundamental da sua existência e modelo de negócio. Assim, compromete-se a adotar um modelo de negócio sustentável, adotando as melhores práticas ambientais, de circularidade e de ética ao longo de toda a sua cadeia de valor.",
      "SUSTAINABLE-POLICY3": "Tal inclui em primeiro lugar o respeito pelo meio-ambiente, economia circular e ética adotando as melhores práticas ambientais adequadas à nossa atividade, incluindo a utilização de ferramentas digitais para otimização de processos, incluindo comunicação em teletrabalho, partilha de conhecimento, monitorização de projetos.",
      "SUSTAINABLE-POLICY4": "Âmbito",
      "SUSTAINABLE-POLICY5": "Investigação, Desenvolvimento e Inovação na área do Tratamento de Dados para os sectores Agroflorestal e Ambiental.",
      "SUSTAINABLE-POLICY6": "Toda a organização é abrangida pelos objetivos de sustentabilidade, estando todos os colaboradores cientes das suas responsabilidades para com a empresa e para com os seus clientes e fornecedores.",
      "SUSTAINABLE-POLICY7": "Os Princípios de Sustentabilidade da AgroInsider",
      "SUSTAINABLE-POLICY8": "De forma a cumprir com este modelo de negócio estabelece objetivos de sustentabilidade, incluindo a contribuição para a descarbonização das atividades económicas dos seus clientes e a redução da sua pegada ambiental através de redução de consumos e de emissões, bem como a promoção destes junto de clientes e fornecedores.",
      "SUSTAINABLE-POLICY9": "Os Objectivos Prioritários para o Desenvolvimento Sustentável",
      "SUSTAINABLE-POLICY10": "Também destaca ainda os principais objetivos de desenvolvimento sustentável para os quais entende possível e adequado contribuir",
      "SUSTAINABLE-POLICY11": "Os Compromissos",
      "SUSTAINABLE-POLICY12": "Compromete-se a desenvolver projectos de redução e/ou compensação de pegada de carbono agrícola com parceiros respeitadores das melhores práticas ESG e a contribuir para o restauro de ecossistemas, promoção da biodiversidade e respeito pelas pessoas.",
      "SUSTAINABLE-POLICY13": "Propõe-se atingir os seus objetivos de proteção do ambiente diretamente, através de racionalização de consumos nas suas instalações, promoção de práticas de uso racional no centro empresarial onde se insere e naturalmente através da sua atividade de consultoria e negócios junto dos seus clientes e fornecedores.",
      "SUSTAINABLE-POLICY14": "Compromete-se a reduzir perdas e desperdícios de materiais, prezar a conservação e boa manutenção dos seus equipamentos, de forma a prolongar a sua durabilidade, de modo a cumprir os objetivos gerais para a reutilização, reparação, renovação e reciclagem de produtos e materiais e contribuir para a proteção do ambiente, integrando a prevenção da poluição.",
      "SUSTAINABLE-POLICY15": "Em concreto,",
      "SUSTAINABLE-POLICY16": "Compromete-se a cumprir a legislação e as melhores práticas associadas à sustentabilidade.",
      "SUSTAINABLE-POLICY17": "Compromete-se a melhorar continuamente o seu desempenho de sustentabilidade, adotando ou desenvolvendo tecnologias cada vez mais sustentáveis, através da monitorização do estado da arte e de novas tecnologias a adotar para atingir este fim",
      "SUSTAINABLE-POLICY18": "Implementação da Política",
      "SUSTAINABLE-POLICY19": "Esta política será comunicada aos colaboradores aquando da reunião de início de ano ou sempre que revista. Está disponibilizada em meios físicos, à entrada do escritório e digitais, na pasta Sustentabilidade de Google Drive e no site da empresa.",
      "SUSTAINABLE-POLICY20": "Aprovação e Revisão da Política",
      "SUSTAINABLE-POLICY21": "Entra em vigor após aprovação pela administração e comunicação e será acompanhada pelo responsável pela sustentabilidade, a nomear anualmente pela administração.",
      "SUSTAINABLE-POLICY22": "Responsável pela Política",
      "SUSTAINABLE-POLICY23": "A administração nomeia anualmente a pessoa responsável pela Política de Sustentabilidade. Esta pessoa deverá estar a par da DNPT4577-3, ter conhecimentos na área de sustentabilidade e transição digital.",
      "SUSTAINABLE-POLICY27": "A pessoa responsável terá a seu cargo a actualização de documentos, de acordo com necessidades sentidas ou requisitos da norma; a verificação do cumprimento dos compromissos para com a sustentabilidade; a marcação e acompanhamento de auditorias anuais, bem como a escolha dos auditores, segundo critérios de isenção e objetividade, tal como definido na Ferramenta de Auditoria.",
      "SUSTAINABLE-POLICY24": "Para o ano de 2024, foi nomeada responsável Vanessa Duarte.",
      "SUSTAINABLE-POLICY25": "Incumprimento da Política",
      "SUSTAINABLE-POLICY26": "O incumprimento da política de sustentabilidade lesa o fundamento e razão de ser da AgroInsider.",
      "SUSTAINABILITY-POLICY": "Politíca de Sustentabilidade",
      "CARBON_CREDITS": "CRÉDITOS DE CARBONO",
      "PRIVACY24": "Através do envio de um email para info@agroinsider.com",
      "PRIVACY23": "Por quanto tempo guardamos os seus dados pessoais?",
      "PRIVACY22": "“Cookies” são pequenos ficheiros de texto que podem ser armazenadas no seu computador através do navegador de internet (browser), retendo apenas informações relacionadas com as suas preferências, não incluindo, como tal, os seus dados pessoais. Guardam informações genéricas, designadamente a forma ou local/país de acesso dos utilizadores e o modo como usam os sites, entre outros. Os cookies retêm apenas informação relacionada com as suas preferências.",
      "PRIVACY21": "Como usamos os “cookies”?",
      "PRIVACY20": "Na página RGPD",
      "PRIVACY19": "Mediante pedido por escrito para o email info@agroinder.com",
      "PRIVACY18": "Diretamente na página de cliente (a minha conta)",
      "PRIVACY17": "Sem prejuízo do disposto no RGPD, o titular dos dados pessoais poderá fazê-lo:",
      "PRIVACY16": "Como poderá aceder, retificar, atualizar, limitar, apagar, opor-se ao tratamento dos seus dados pessoais, ou remover o consentimento?",
      "PRIVACY15": "Enquanto titulares dos dados pessoais, é garantido aos Clientes/Utilizadores, a qualquer momento, o direito de acesso, retificação, atualização, limitação e apagamento dos seus dados pessoais (salvo quando os dados são indispensáveis à prestação dos serviços e ao cumprimento de obrigações legais), o direito de oposição à utilização dos mesmos para fins comerciais e à retirada do consentimento e o direito à portabilidade dos dados.",
      "PRIVACY14": "Quais são os direitos do Cliente/Utilizador?",
      "PRIVACY13": "O período de tempo durante o qual os dados pessoais são armazenados e conservados varia de acordo com a finalidade para a qual a informação é tratada. Salvaguardando requisitos legais, os dados serão armazenados e conservados apenas pelo período mínimo necessário, para a prossecução das finalidades que motivaram a sua recolha, ou o seu posterior tratamento, nos termos definidos na lei.",
      "PRIVACY12": "Por quanto tempo guardamos os seus dados pessoais?",
      "PRIVACY11": "Os dados dos nossos clientes e Utilizadores são guardados automaticamente num servidor seguro localizado em Portugal, sendo armazenados em bases de dados específicas, criadas para o efeito. Estes servidores são protegidos e mantidos de acordo com os mais elevados standards de segurança e por forma a respeitar as leis de privacidade aplicáveis. Caso deseje ser removido da nossa base de dados, poderá exercer esse direito, contactando-nos através dos diversos meios disponíveis no site.",
      "PRIVACY10": "Onde são guardados os dados do Utilizador?",
      "PRIVACY9": "Através da Área de Cliente",
      "PRIVACY8": "Se o titular dos dados pessoais precisar de entrar em contacto com o responsável pelo tratamento de dados, poderá fazê-lo através dos meios e contactos abaixo indicados:",
      "PRIVACY7": "A nossa empresa nunca divulga a terceiros quaisquer dados pessoais dos seus Clientes e Utilizadores, sem o seu consentimento, exceto quando tal for exigido por lei, sendo da nossa responsabilidade o tratamento dos dados pessoais do utilizador.",
      "PRIVACY6": "Quem tem acesso aos dados pessoais do Utilizador e quem é o responsável pelo tratamento de dados",
      "PRIVACY5": "Os dados recolhidos nos formulários do website destinam-se exclusivamente ao processamento dos pedidos de informação e contacto dos Clientes/Utilizadores, nomeadamente dados como o nome, a morada, o número de telefone e o endereço de correio eletrónico e não serão usados para qualquer outro processamento. Esta informação não é, em caso algum, fornecida a terceiros, destinando-se unicamente a servi-lo melhor. Esta, será exclusivamente usada no seguimento de negociações/contactos efetuados consigo.",
      "PRIVACY4": "Para que fins são recolhidos dados pessoais do Utilizador",
      "PRIVACY3": "A nossa empresa cumpre e respeita a privacidade do Cliente/Utilizador e processa os seus dados pessoais de acordo com as leis em vigor. Os dados não serão processados para quaisquer outros fins que não os aqui especificados, reservando-se o direito de atualizar ou modificar a Política de Privacidade a qualquer momento, nomeadamente, por forma a adaptá-la a alterações legislativas.",
      "PRIVACY2": "No nosso website poderão estar incluídos links de acesso a outros websites pelos quais não somos responsáveis e o Utilizador deverá consultar as políticas de privacidade dos mesmos.",
      "PRIVACY1": "A presente política de privacidade abrange este website. Nesta Política pretende-se facultar aos Clientes/Utilizadores o conhecimento das regras gerais de tratamento de dados pessoais, os quais são recolhidos e tratados no estrito respeito e cumprimento do disposto na legislação de proteção de dados pessoais em vigor em cada momento, nomeadamente o Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de 2016 (“RGPD”).",
      "PRIVACY_POLICY": "Política de Privacidade",
      "IDI-TEXT1": "A AgroInsider tem na Sustentabilidade a raiz da sua génese, sendo as atividades de Investigação, Desenvolvimento e Inovação, a base da sua criação, crescimento e valorização.",
      "IDI-TEXT2": "A AgroInsider assume o compromisso de contribuir para processos sustentáveis na Economia Agroflorestal e Ambiental através de uma monitorização contínua das tendências de mercado e da tecnologia, do fomento de uma cultura de inovação e do desenvolvimento de soluções inovadoras de análise, monitorização, verificação e relatório.",
      "IDI-TEXT3": "A AgroInsider assume um forte compromisso para com a Inovação assente numa estratégia gerida e operacionalizada através de um Sistema de gestão da IDI (SGIDI), implementado segundo os requisitos da NP 4457: 2021.",
      "IDI-TEXT4": "A AgroInsider assume o compromisso de garantir a eficácia do SGIDI e a sua melhoria contínua através da identificação de oportunidade e riscos e avaliações em intervalos planeados, de modo a implementar ações e avaliar a eficácia destas.",
      "IDI-TEXT5": "A sua estratégia de IDI assenta nos seguintes pilares:",
      "IDI-TEXT6": "Alinhamento da Estratégia de IDI com os pilares estratégicos da empresa.",
      "IDI-TEXT7": "Fomento de uma cultura criativa, colaborativa, ao serviço da inovação.",
      "IDI-TEXT8": "Conhecimento do mercado através de uma interação contínua com clientes e stakeholders, investindo em processos de co-criação, com o objetivo de promover a adoção de novas soluções.",
      "IDI-TEXT9": "Acompanhamento do estado da arte das tecnologias emergentes e estabelecimento de relações sólidas com Ecossistemas de IDI Nacionais e Internacionais.",
      "IDI-TEXT10": "Integração em consórcios alargados para participação em projetos exploratórios e de inovação europeus e nacionais.",
      "IDI-TEXT11": "Valorização dos resultados da IDI incorporando-os nas suas atividades de Prestação de serviços.",
      "IDI-TEXT12": "Melhorias significativas nas plataformas tecnológicas da AgroInsider em resposta às necessidades do cliente e às tendências do mercado.",
      "IDI": "Política IDI",
      "VISION-MISSION-TITLE": "A Nossa Visão & Missão",
      "VISION-MISSION-SUBTITLE": "Contribuir para a Sustentabilidade do Planeta",
      "VISION": "Visão",
      "VISION-TEXT": "Promover a Neutralidade climática global contribuindo para o cumprimento dos objetivos europeus de neutralidade para 2035 e 2050.",
      "MISSION": "Missão",
      "MISSION-TEXT": "Valorização do capital natural de empresas agro-florestais criando melhores práticas na gestão do balanço de carbono. A partir de dados de observação da Terra produzir conhecimento intensivo através de monitorização, verificação e relatório, tornando-o socialmente relevante à tomada de decisão na gestão do capital natural.",
      "VALUES": "Valores",
      "VALUES-TEXT": "Respeito pela natureza e pela diferença, Honestidade, Espírito de sacrifício, Trabalho em equipa, Resiliência.",
      "ABOUT-US-TEXT-TWO": "Ajudamos as empresas a serem mais eficientes e a reduzir a sua pegada. Trabalhando com dados de satélite, podemos verificar em qualquer parte da Terra como as florestas e as culturas estão a evoluir. Podemos então ajudar as indústrias a acompanhar as suas importações e a proteger o ambiente.",
      "ABOUT-US-TEXT-ONE": "Somos alimentados por uma energia renovável: o nosso amor pelas pessoas e pelo planeta. ",
      "WHO-ARE-WE": "Quem somos nós e o que nos move?",
      "NAVBAR-ABOUTUS": "Sobre nós",
      "SEND": "ENVIAR",
      "PHONENUMBER": "Número de telemóvel",
      "MESSAGE": "Mensagem",
      "EMAIL": "Email",
      "NEWS": "Novidades",
      "ERROR": "Erro",
      "INFO": "Info",
      "SUCCESS": "Sucesso",
      "THANK_CONTACT": "Obrigado por nos contactar!",
      "EMAIL_INVALID": "O e-mail não é válido",
      "FILL_ALL_FIELDS": "Preencher todos os campos",
      "ERROR_SUBMITING_FORM": "Erro ao submeter formulário, tente outra vez",
      "INVALID_DATE": "Data inválida, escolha outra data",
      "AT": "em",
      "MEETING_SCHEDULED_FOR": "Reunião agendada para",
      "OTHER": "OUTRO",
      "SCHEDULE_CALL": "AGENDAR CHAMADA",
      "HERE": "aqui",
      "PERSONAL_DATA": "Os dados pessoais recolhidos serão tratados de acordo com os termos definidos na política de privacidade, disponíveis",
      "DATE": "Data",
      "CALL_DETAILS": "Por favor, introduza os seus dados e escolha uma data para que possamos contactá-lo.",
      "WANT_TO_SCHEDULE_A_CALL": "QUER AGENDAR UMA CHAMADA?",
      "COUNTRY": "País",
      "SUBJECT": "Assunto",
      "FILL-ALL-FIELDS": "Preencher todos os campos",
      "ERROR-SUBMITING-FORM": "Erro a submeter form",
      "INVALID-DATE": "Data inválida, escolha outra data",
      "AT": "em",
      "MEETING-SCHEDULED-FOR": "Reunião agendada para",
      "HERE": "aqui",
      "PERSONAL-DATA": "Os dados pessoais recolhidos serão tratados de acordo com os termos definidos na política de privacidade, disponíveis",
      "SCHEDULE-CALL": "AGENDAR CHAMADA",
      "DATE": "Data",
      "ENTER-NUMBER": "Introduza o seu número",
      "NAME": "Nome",
      "PLEASE-MEETING": "Por favor, introduza os seus dados e escolha uma data para que possamos contactá-lo",
      "WANT-SHEDULE-MEETING": "QUER AGENDAR UMA CHAMADA?",
      "THANK-YOU": "Obrigado",
      "NAVBAR-HOME": "Home",
      "NAVBAR-ABOUT": "Sobre nós",
      "NAVBAR-SERVICES": "Serviços",
      "NAVBAR-NEWS": "Novidades",
      "NAVBAR-APPS": "Apps",
      "NAVBAR-BLOG": "Blog",
      "NAVBAR-CONTACTUS": "Contacte nos",
      "FOLLOW": "Siga-nos: ",

      "ENTER-EMAIL": "Introduza aqui o seu email",

      "CLIENT-AREA-TITLE": "Area Cliente",

      "NEWSLETTER": "Subscreve a nossa newsletter",
      "NEWSLETTER-SUBSCRIBE": "SUBSCREVER",


      "ABOUTUS-TITLE": "Sobre nós",
      "ABOUTUS-ENVIRONMENTAL-TITLE": "Sustentabilidade Ambiental e Agrícola",
      "ABOUTUS-ENVIRONMENTAL-TEXT": "Trabalhamos com o propósito de promover a sustentabilidade ambiental de modo que a agricultura se torne mais positiva. Um mundo carbono neutro, gera uma agricultura positiva.",
      "ABOUTUS-CARBON-TITLE": "Especialistas no Mercado de Carbono",
      "ABOUTUS-CARBON-TEXT-ONE": "Empresa especializada e vocacionada para mercado do carbono.",
      "ABOUTUS-CARBON-TEXT-TWO": "Desenvolvemos projetos de quantificação do carbono na sua exploração.",

      "ABOUTUS-KNOW-MORE": "SABER MAIS",
      "ABOUTUS-INNOVATION-TITLE": "Inovação para o seu Negócio",
      "ABOUTUS-INNOVATION-TEXT": "Inovamos o seu negócio com as nossas mais avançadas tecnologias. Unimos a tecnologia de satélite com a praticidade de uma aplicação móvel.",
      "ABOUTUS-DISCOVER-APPS": "DESCUBRA AS APP'S",

      "SERVICES": "Serviços",
      "SERVICES-CARD1-TITLE": "Auditoria",
      "SERVICES-CARD1-SUBTITLE": "Analisamos as atividades realizadas na sua exploração, quantificamos e emitimos um certificado com a sua pegada ambiental, atribuindo valor monetário a ser comercializado.",

      "SERVICES-CARD2-TITLE": "Consultoria",
      "SERVICES-CARD2-SUBTITLE": "Gerimos e asseguramos a melhor qualidade de todo o processo de cultivo. Aconselhamos as melhores práticas de gestão.",

      "SERVICES-CARD3-TITLE": "Broker",
      "SERVICES-CARD3-SUBTITLE": "Auxiliamos na compra e venda dos seus créditos de carbono no mercado internacional.",

      "CONTACTUS-TITLE": "Contacto",
      "CONTACTUS-SUBTITLE": "Faça parte do futuro connosco. Poupe tempo e dinheiro.",

      "CARBON-TITLE": "Soluções de carbono",

      "CARBON-CARD1-TITLE": "Agricultura Inteligente",
      "CARBON-CARD1-SUBTITLE": "Maior eficiência agrícola gera maior eficiência de carbono.",


      "CARBON-CARD2-TITLE": "Biodiversidade",
      "CARBON-CARD2-SUBTITLE": "Planear o aproveitamento de áreas não cultivadas para o sequestro de carbono.",

      "CARBON-CARD3-TITLE": "Créditos CO₂",
      "CARBON-CARD3-SUBTITLE": "Quantificação dos créditos de carbono, validação, registo e comercialização.",

      "APPS-WEBAPPS": "Agroinsider Web Apps",
      "APPS-WEBAPPS-TEXT": "Através da combinação da gestão agrícola e dados capturada utilizando tecnologia satélite, a Agroinsider oferece plataformas e aplicações únicas para produtores, de modo a promover decisões eficientes.",
      "APPS-GET-STARTED": "SABER MAIS",

      "APPS-FEATURES": "Funções",
      "APPS-FEATURES-ICON1-TITLE": "Relatórios Semanais",
      "APPS-FEATURES-ICON1-TEXT": "Relatórios semanais de acordo com o estado da sua exploração. Com variações de cor (verde, amarelo ou vermelho) consoante a maior variabilidade.",

      "APPS-FEATURES-ICON2-TITLE": "Criação de áreas de gestão",
      "APPS-FEATURES-ICON2-TEXT": "Mapas de gestão de acordo com as zonas que apresentem variabilidades distintas na sua parcela. Facilidade no momento de utilização de inputs de forma sustentável.",

      "APP-FEATURES-TITLE": "O sistema de alertas Agromap e Smartag!",

      "APP-ALERTS": "Alertas",

      "APPS-FEATURES-CARD1-TITLE": "Sistemas de rega",
      "APPS-FEATURES-CARD1-SUBTITLE": "Otimizamos o seu uso de água e detetamos possíveis problemas no sistema de rega.",

      "APPS-FEATURES-CARD2-TITLE": "Desvio de produtividade",
      "APPS-FEATURES-CARD2-SUBTITLE": "Acompanhamento em tempo real do desenvolvimento vegetativo da sua cultura.",

      "APPS-FEATURES-CARD3-TITLE": "Anomalias",
      "APPS-FEATURES-CARD3-SUBTITLE": "Segundo os nossos algoritmos são gerados alertas amarelos ou vermelhos de acordo com a variabilidade da parcela.",
      "PROJECTS": "Projects",

      "CLIENT-AREA-TITLE": "Área Cliente",
      "START": "COMEÇAR"
    }
  }
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en-GB',
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
export default i18n;