import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import './Contactform.css'
import i18n from '../i18n';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import PhoneInput from 'react-phone-number-input';

const apiBase = process.env.REACT_APP_API_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const Contactform = (props) => {
    const { t, lang } = props;

    const [state, setState] = useState({
        name: "",
        email: "",
        subject: "",
        country: "",
        phone: "",
        message: ""
    });

    function handleOptionChange(event) {
        setState(prev => ({ ...prev, subject: event.target.value }));
    }

    async function handleSendForm() {
        const { name, email, subject, country, phone, message } = state;
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (name && email && subject && country && phone && message) {
            try {
                if (regex.test(email)) {
                    await fetch(`${apiBase}co2message/${apiKey}`, {
                        method: 'POST',
                        body: JSON.stringify({
                            name,
                            email,
                            subject,
                            country,
                            phone,
                            message,
                            creationSource: "Agroinsider Website"
                        }),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    NotificationManager.success(t("THANK_CONTACT"), t('SUCCESS'), 5000);
                    setState({
                        name: "",
                        email: "",
                        subject: "",
                        country: "",
                        phone: "",
                        message: ""
                    })
                } else {
                    NotificationManager.error(t("EMAIL_INVALID"), t('INFO'), 5000);
                }
            } catch (e) {
                NotificationManager.error(t("ERROR_SUBMITING_FORM"), t('ERROR'), 5000);
            }
        }
        else {
            NotificationManager.error(t("FILL_ALL_FIELDS"), t('INFO'), 5000);
        }
    }

    return (
        <>
            <span className='title-contactus'>{t("NAVBAR-CONTACTUS")}</span>
            <div id="contactus" className='pipedrive-contact-form'>
                <NotificationContainer />
                <div className='container'>
                    <span>{t("NAME")} *</span>
                    <input className='pipedrive-input' name="name" value={state.name} onChange={e => setState(prev => ({ ...prev, name: e.target.value }))} />
                    <span>{t("EMAIL")} *</span>
                    <input type='email' required className='pipedrive-input' name="email" value={state.email} onChange={e => setState(prev => ({ ...prev, email: e.target.value }))} />
                    <span>{t("SUBJECT")} *</span>
                    <label>
                        <input
                            className='pipedrive-radio'
                            type="radio"
                            name="options"
                            value="agro"
                            checked={state.subject === "agro"}
                            onChange={handleOptionChange}
                        />
                        AGRO
                    </label>
                    <label>
                        <input
                            className='pipedrive-radio'
                            type="radio"
                            name="options"
                            value="co2"
                            checked={state.subject === "co2"}
                            onChange={handleOptionChange}
                        />
                        {t("CARBON_CREDITS")}
                    </label>
                    <label>
                        <input
                            className='pipedrive-radio'
                            type="radio"
                            name="options"
                            value="forest"
                            checked={state.subject === "forest"}
                            onChange={handleOptionChange}
                        />
                        FOREST
                    </label>
                    <label>
                        <input
                            className='pipedrive-radio'
                            type="radio"
                            name="options"
                            value="other"
                            checked={state.subject === "other"}
                            onChange={handleOptionChange}
                        />
                        {t("OTHER")}
                    </label>
                    <span>{t("COUNTRY")} *</span>
                    <input className='pipedrive-input' name="country" value={state.country} onChange={e => setState(prev => ({ ...prev, country: e.target.value }))} />
                    <span>{t("PHONENUMBER")} *</span>
                    <div className='pipedrive-input'>
                        <PhoneInput
                            style={{
                                width: "100%",
                                right: "0px"
                            }}
                            international
                            defaultCountry="PT"
                            placeholder="Enter phone number"
                            value={state.phone}
                            onChange={e => setState(prev => ({ ...prev, phone: e }))} />
                    </div>
                    <span>{t("MESSAGE")} *</span>
                    <textarea name="message" value={state.message} onChange={e => setState(prev => ({ ...prev, message: e.target.value }))} />
                    <button onClick={handleSendForm} style={{
                        backgroundColor: "#04A953",
                        padding: "1em",
                        border: "none",
                        color: "white",
                        borderRadius: "0.5em",
                        marginTop: "1em",
                        fontWeight: "bold"
                    }}>{t("SEND")}</button>
                </div>

            </div>
        </>
        // <> 
        //     {
        //         ((i18n.language === "en-GB") || (i18n.language !== "es-ES"  && i18n.language !== "pt-PT" && i18n.language !== "en-GB")) && (
        //             <div id="contactus">
        //                 <div className="contactform-title">
        //                     <span>{t("CONTACTUS-TITLE")}</span>
        //                 </div>
        //                 <div className="contactform-subtitle">
        //                     <span>{t("CONTACTUS-SUBTITLE")}</span>
        //                 </div>
        //                 <div className="contactform-card-container" data-pd-webforms={'https://webforms.pipedrive.com/f/1wBom3MnJxIaDPQuyaACeZzwWpbq5EoUpaXCv92xnNrzHRSJtf5pxQ7So51ceWKe7'}>
        //                     <iframe
        //                         src={`https://webforms.pipedrive.com/f/1wBom3MnJxIaDPQuyaACeZzwWpbq5EoUpaXCv92xnNrzHRSJtf5pxQ7So51ceWKe7`}

        //                         scrolling="no"
        //                         seamless="seamless"
        //                         style={{
        //                             border: 'none',
        //                             overflow: 'hidden',
        //                             width: '100%',
        //                             maxWidth: '768px',
        //                             minWidth: '320px',
        //                             height: '60em',
        //                             position: 'relative',
        //                         }}
        //                     />
        //                 </div>
        //             </div>
        //         )
        //     }
        //     {
        //         i18n.language === "pt-PT" && (
        //             <div id="contactus">
        //                 <div className="contactform-title">
        //                     <span>{t("CONTACTUS-TITLE")}</span>
        //                 </div>
        //                 <div className="contactform-subtitle">
        //                     <span>{t("CONTACTUS-SUBTITLE")}</span>
        //                 </div>
        //                 <div className="contactform-card-container" data-pd-webforms={'https://webforms.pipedrive.com/f/2XCO8cDD1JGYR6KLsFZePPhNBJk9cd5Xt4bWGUbnmYqBgbrGhKcP8jtYeciPQTsqf'}>
        //                     <iframe
        //                         src={`https://webforms.pipedrive.com/f/2XCO8cDD1JGYR6KLsFZePPhNBJk9cd5Xt4bWGUbnmYqBgbrGhKcP8jtYeciPQTsqf`}

        //                         scrolling="no"
        //                         seamless="seamless"
        //                         style={{
        //                             border: 'none',
        //                             overflow: 'hidden',
        //                             width: '100%',
        //                             maxWidth: '768px',
        //                             height: '60em',

        //                         }}
        //                     />
        //                 </div>
        //             </div>
        //         )

        //     }

        //     {   
        //         i18n.language === "es-ES" && (
        //             <>
        //             <div id="contactus">
        //                 <div className="contactform-title">
        //                     <span>{t("CONTACTUS-TITLE")}</span>
        //                 </div>
        //                 <div className="contactform-subtitle">
        //                     <span>{t("CONTACTUS-SUBTITLE")}</span>
        //                 </div>
        //                 <div className="contactform-card-container" data-pd-webforms={'https://webforms.pipedrive.com/f/1EjNWGrDlBRsSozClhvnXZ5YBTabIoBNpmYDcWJ64n4V6SssnkgnQKevxjrHJ6S4z'}>
        //                     <iframe
        //                         src={`https://webforms.pipedrive.com/f/1EjNWGrDlBRsSozClhvnXZ5YBTabIoBNpmYDcWJ64n4V6SssnkgnQKevxjrHJ6S4z`}

        //                         scrolling="no"
        //                         seamless="seamless"
        //                         style={{
        //                             border: 'none',
        //                             overflow: 'hidden',
        //                             width: '100%',
        //                             maxWidth: '768px',
        //                             minWidth: '320px',
        //                             height: '60em',
        //                             position: 'relative',
        //                         }}
        //                     />
        //                 </div>
        //             </div>
        //             </>
        //         )
        //     }
        // </>
    );
}

export default withTranslation()(Contactform);
