import React from 'react';
import { withTranslation } from 'react-i18next';
import './Card.css'

const Card = (props) => {

    const { t, img, title, subtitle  } = props;

    return (
        <div className="services-card">
            <div className="services-card-text">
                <img src={img} style={{marginBottom:"2em", width:"120px"}} />
                <span className="subcontent-title">{title}</span>
                <span className="subcontent-text">{subtitle}</span>
            </div>
        </div>
    )
}


export default withTranslation()(Card);
