import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';
import './Newsletter.css'
import PopUpNewsletter from './PopUpNewsletter';
const apiBase = process.env.REACT_APP_API_URL;

const Newsletter = (props) => {
    const { t } = props;
    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState("");

    const handleOnclickCloseModal = (closeModal) => {
        setShowModal(closeModal);
    }

    const handleEmailInput = (event) => {
        setEmail(event.target.value);
    }

    const handleSubmit = async () => {
        let body =  { email };
        try {
            let response = await fetch(`${apiBase}/mailchimp/newsletter`, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json"
                  },
                credentials: "same-origin"
            })
            console.log(response);
            setShowModal(true);
        }

        catch(e) {
            console.log(e);
        }
    }

    return (
        <>
        <div className="newsletter-container">
            <div className="newsletter-text-container">
                <div className="newsletter-title">
                    <span>{t("NEWSLETTER")}</span>
                </div>
                <div className="newsletter-form">
                    <input type="email" name="email" onChange={ (event) => handleEmailInput(event)} id="mce-EMAIL" required placeholder={t("ENTER-EMAIL")} className="subscribe-input"></input>
                    <button onClick={ () => handleSubmit()} type="submit" value="Subscribe" name="subscribe" className="subscribe-button">{t("NEWSLETTER-SUBSCRIBE")}</button>
                </div>
            </div>
            <PopUpNewsletter showModal={showModal} onClickCloseModal={handleOnclickCloseModal}/>
        </div>  
        </>
    )
}


export default withTranslation()(Newsletter);
