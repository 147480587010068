
import React from 'react';
import {withTranslation} from 'react-i18next';
import "../node_modules/uikit/dist/css/uikit.min.css"


import HomePage from './Pages/HomePage';
import AppPage from './Pages/AppPage';
import AboutPage from './Pages/AboutPage';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import ClientAreaPage from './Pages/ClientAreaPage';
import ScrollToTop from './components/ScrollToTop';
import PopUpCircle from './components/PopUpCircle';
import AboutUsPage from './Pages/AboutUsPage';


function App(props) {
  return (
    <div>
      <BrowserRouter basename="/">
        <ScrollToTop />
        <Switch>
          <div style={{backgroundColor:"#F8F8F8"}}>
          <Route path='/' exact>
            <Redirect to = '/home' />
          </Route>

          <Route path='/home'>
            <HomePage t = {props.t} />
          </Route>

          <Route path='/about'>
            <AboutPage t = {props.t} />
          </Route>

          <Route path='/aboutus'>
            <AboutUsPage t = {props.t} />
          </Route>

          <Route path='/apps'>
            <AppPage t = {props.t} />
          </Route>

          <Route path='/clientarea'>
            <ClientAreaPage t = {props.t} />
          </Route>
          </div>

        </Switch>
      </BrowserRouter>
      <PopUpCircle t={props.t} />
    </div>
  );
}

export default withTranslation()(App);
