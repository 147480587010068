import logo from "../img/logooriginal.svg";
import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';
import './NavBar.css'
import { Navbar, Container, Nav, NavDropdown, Button } from 'react-bootstrap';
import Language from "./Language";
import { UilArrowRight } from '@iconscout/react-unicons'
import { UilArrowDown } from '@iconscout/react-unicons'
import { HashLink } from "react-router-hash-link";

const NavBar = (props) => {
    const { t } = props;

    const [newsActive, setNewsActive] = useState(false);

    const scrollMe = (e, data) => {
        e.preventDefault();
        let t = document.getElementById(data);
        if (t) {

            window.scrollTo(0, t.offsetTop);
        }
        else {
            window.location = "/home";
            window.scrollTo(0, t.offsetTop);
        }
    };

    const scrollWithOffset = (el, type) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        var yOffset;
        if (type === "contact") {
            yOffset = window.innerWidth <= 960 ? -100 : -60;
        }
        else if (type === "our-pioneers") {
            yOffset = window.innerWidth <= 960 ? -200 : -180;
        }
        else {
            yOffset = window.innerWidth <= 960 ? -200 : -110;
        }
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    return (

        <Navbar style={{ justifyContent: "space-between", margin: "auto", width: "95%" }} expand="lg">
            <Navbar.Brand href="#home"><img onClick={scrollMe} className="navbar-img" src={logo} /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav style={{ marginLeft: "auto" }}>
                    <div className="navbar-other-buttons">
                        <HashLink smooth scroll={el => scrollWithOffset(el)} exact to="/home"><a style={{ color: "#434444", fontWeight: "bold" }}>{t("NAVBAR-HOME")}</a></HashLink>
                    </div>
                    <div className="navbar-other-buttons">
                        <HashLink smooth scroll={el => scrollWithOffset(el)} exact to="/#about-us-home"><a style={{ color: "#434444", fontWeight: "bold" }}>{t("NAVBAR-ABOUTUS")}</a></HashLink>
                    </div>
                    <div className="navbar-other-buttons">
                        <HashLink smooth scroll={el => scrollWithOffset(el)} exact to="/#services"><a style={{ color: "#434444", fontWeight: "bold" }}>{t("NAVBAR-SERVICES")}</a></HashLink>
                    </div>
                    <div className="navbar-other-buttons">
                        <HashLink smooth scroll={el => scrollWithOffset(el)} exact to="/apps"><a style={{ color: "#434444", fontWeight: "bold" }}>{t("NAVBAR-APPS")}</a></HashLink>
                    </div>
                    <div className="navbar-other-buttons">
                        <Nav.Link target="_blank" href="//blog.agroinsider.com"><a style={{ color: "#434444", fontWeight: "bold" }}>{t("NAVBAR-BLOG")}</a></Nav.Link>
                    </div>
                    <div className="navbar-other-buttons">
                        <HashLink smooth scroll={el => scrollWithOffset(el)} exact to="/clientarea"><a style={{ color: "#434444", fontWeight: "bold" }}>{t("CLIENT-AREA-TITLE")}</a></HashLink>
                    </div>
                    <div className={`navbar-other-buttons ${newsActive && 'no-background-color'}`}>
                            <div onClick={e => setNewsActive(!newsActive)} style={{display:"flex"}}>
                                <Nav.Link><a style={{ color: "#434444", fontWeight: "bold" }}>{t("NEWS")}</a></Nav.Link>
                                {newsActive ? 
                                    (<UilArrowDown style={{cursor:"pointer"}} size="25" color="black" />)
                                    : 
                                    <UilArrowRight style={{cursor:"pointer"}} size="25" color="black" />
                                }
                            </div>
                            {newsActive && (
                                <div className="navbar-other-buttons-news">
                                    <Nav.Link onClick={e => window.open("https://co2.agroinsider.com/", "_blank")}><a style={{ color: "#434444", fontWeight: "bold" }}>CO<sub>2</sub></a></Nav.Link>
                                    <Nav.Link onClick={e => window.open("https://forest.agroinsider.com/", "_blank")}><a style={{ color: "#434444", fontWeight: "bold" }}>{t("Forest")}</a></Nav.Link>
                                </div>
                            )
                            }
                        </div>
                    <div className="navbar-other-buttons">
                        <HashLink smooth scroll={el => scrollWithOffset(el)} exact to="/#contactus"><a style={{ color: "#434444", fontWeight: "bold" }}>{t("NAVBAR-CONTACTUS")}</a></HashLink>
                    </div>
                    <div className="navbar-other-buttons">
                        <Language />
                    </div>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}


export default withTranslation()(NavBar);
