import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import './AboutUs.css'
import aboutImage from '../img/img_vision_mission.png';
import vision from '../img/visao.svg';
import values from '../img/valores.svg';
import mission from '../img/missao.svg';
import right from '../img/right.svg';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pdfFile from '../../src/img/politicasustentabilidade.pdf';
import goals from '../../src/img/2024_goals.png'
import goals2 from '../../src/img/goals_2024_2.png'
import prioritys from '../../src/img/priority_goals.png'

const AboutUs = (props) => {
    const { t } = props;
    const [navbarMode, setNavbarMode] = useState("VISIONMISSION");

    const renderNav = (navbarName) => {
        if (navbarName === "VISIONMISSION") {
            return (
                <div className='mission-vision-img-container'>
                    <div className='mission-vision-container'>
                        <span className='title'>{t("VISION-MISSION-TITLE")}</span>
                        <span className='text' style={{
                            color: "#434444"
                        }}>{t("VISION-MISSION-SUBTITLE")}</span>
                        <div className='mission-vision-subcontainer'>
                            <img src={vision} style={{ marginRight: "2em" }} />
                            <div style={{
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <span className='subcontent-title' style={{ textAlign: "left", height: "2vw" }}>{t("VISION")}</span>
                                <span className='subcontent-text'>{t("VISION-TEXT")}</span>
                            </div>
                        </div>

                        <div className='mission-vision-subcontainer'>

                            <img src={mission} style={{ marginRight: "2em" }} />
                            <div style={{
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <span className='subcontent-title' style={{ textAlign: "left", height: "2vw" }}>{t("MISSION")}</span>
                                <span className='subcontent-text'>{t("MISSION-TEXT")}</span>
                            </div>
                        </div>

                        <div className='mission-vision-subcontainer'>
                            <img src={values} style={{ marginRight: "2em" }} />
                            <div style={{
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <span className='subcontent-title' style={{ textAlign: "left", height: "2vw" }}>{t("VALUES")}</span>
                                <span className='subcontent-text'>{t("VALUES-TEXT")}</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src={aboutImage} />
                    </div>
                </div>
            )
        } else if (navbarName === "SUSTAINABILITY-POLICY") {
            return (
                <div style={{ marginTop: "3em", color: "#434444" }}>
                    <div className='mission-vision-container' style={{ width: "80%" }}>
                        <span className='title'>{t("SUSTAINABILITY-POLICY")}</span>
                        <span style={{
                            marginTop: "3em",
                            marginBottom: "1em",
                            fontWeight: "500",
                            color: "#04A953"
                        }} className='text'>{t("SUSTAINABLE-POLICY1")}</span>
                        <span className='text'>{t("SUSTAINABLE-POLICY2")}</span>
                        <span className='text'>{t("SUSTAINABLE-POLICY3")}</span>
                        <span style={{
                            marginTop: "3em",
                            marginBottom: "1em",
                            fontWeight: "500",
                            color: "#04A953"
                        }} className='text'>{t("SUSTAINABLE-POLICY4")}</span>
                        <span className='text'>{t("SUSTAINABLE-POLICY5")}</span>
                        <span className='text'>{t("SUSTAINABLE-POLICY6")}</span>
                        <span style={{
                            marginTop: "3em",
                            marginBottom: "1em",
                            fontWeight: "500",
                            color: "#04A953"
                        }} className='text'>{t("SUSTAINABLE-POLICY7")}</span>
                        <span className='text'>{t("SUSTAINABLE-POLICY8")}</span>
                        <span style={{
                            marginTop: "3em",
                            marginBottom: "1em",
                            fontWeight: "500",
                            color: "#04A953"
                        }} className='text'>{t("SUSTAINABLE-POLICY9")}</span>
                        <span className='text'>{t('SUSTAINABLE-POLICY10')}</span>
                        <img style={{marginTop:"2em", width:"fit-content"}} src={prioritys} />
                        <span style={{
                            marginTop: "3em",
                            marginBottom: "1em",
                            fontWeight: "500",
                            color: "#04A953"
                        }} className='text'>{t("SUSTAINABLE-POLICY11")}</span>
                        <span className='text'>{t("SUSTAINABLE-POLICY12")}</span>
                        <span className='text'>{t("SUSTAINABLE-POLICY13")}</span>
                        <img style={{marginTop:"2em", width:"fit-content"}} src={t("img-goals")} />
                        <span className='text'>{t("SUSTAINABLE-POLICY14")}</span>
                        <span className='text'>{t("SUSTAINABLE-POLICY16")}</span>
                        <span className='text'>{t("SUSTAINABLE-POLICY17")}</span>
                        <span style={{
                            marginTop: "3em",
                            marginBottom: "1em",
                            fontWeight: "500",
                            color: "#04A953"
                        }} className='text'>{t("SUSTAINABLE-POLICY18")}</span>
                        <span className='text'>{t("SUSTAINABLE-POLICY19")}</span>
                        <span style={{
                            marginTop: "3em",
                            marginBottom: "1em",
                            fontWeight: "500",
                            color: "#04A953"
                        }} className='text'>{t("SUSTAINABLE-POLICY20")}</span>
                        <span className='text'>{t("SUSTAINABLE-POLICY21")}</span>
                        <span style={{
                            marginTop: "3em",
                            marginBottom: "1em",
                            fontWeight: "500",
                            color: "#04A953"
                        }} className='text'>{t("SUSTAINABLE-POLICY22")}</span>
                        <span className='text'>{t("SUSTAINABLE-POLICY23")}</span>
                        <span className='text'>{t("SUSTAINABLE-POLICY27")}</span>
                        <span className='text'>{t("SUSTAINABLE-POLICY24")}</span>
                        <span style={{
                            marginTop: "3em",
                            marginBottom: "1em",
                            fontWeight: "500",
                            color: "#04A953"
                        }} className='text'>{t("SUSTAINABLE-POLICY25")}</span>
                        <span className='text'>{t("SUSTAINABLE-POLICY26")}</span>
                    </div>
                </div>
                // <div onClick={() => window.open(pdfFile, '_blank')} style={{
                //     marginLeft: "10em",
                //     marginTop: "3em",
                //     cursor:"pointer"
                // }}>
                //     <FontAwesomeIcon icon={faFilePdf} size="3x" />
                // </div>

            )
        } else if (navbarName === "IDI") {
            return (
                <div style={{ marginTop: "3em", color: "#434444" }}>
                    <div className='mission-vision-container' style={{ width: "80%" }}>
                        <span className='title'>{t("IDI")}</span>
                        <span className='text'>{t("IDI-TEXT1")}</span>
                        <span className='text'>{t("IDI-TEXT2")}</span>
                        <span className='text'>{t("IDI-TEXT3")}</span>
                        <span className='text'>{t("IDI-TEXT4")}</span>
                        <span style={{
                            marginTop: "3em",
                            marginBottom: "3em",
                            fontWeight: "500"
                        }} className='text'>{t("IDI-TEXT5")}</span>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ marginTop: "2em", marginRight: "2em" }} src={right} width="30px" />
                            <span className='text'>{t("IDI-TEXT6")}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ marginTop: "2em", marginRight: "2em" }} src={right} width="30px" />
                            <span className='text'>{t("IDI-TEXT7")}</span>
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ marginTop: "2em", marginRight: "2em" }} src={right} width="30px" />
                            <span className='text'>{t("IDI-TEXT8")}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ marginTop: "2em", marginRight: "2em" }} src={right} width="30px" />
                            <span className='text'>{t("IDI-TEXT9")}</span>
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ marginTop: "2em", marginRight: "2em" }} src={right} width="30px" />
                            <span className='text'>{t("IDI-TEXT10")}</span>
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ marginTop: "2em", marginRight: "2em" }} src={right} width="30px" />
                            <span className='text'>{t("IDI-TEXT11")}</span>
                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ marginTop: "2em", marginRight: "2em" }} src={right} width="30px" />
                            <span className='text'>{t("IDI-TEXT12")}</span>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ marginTop: "3em", color: "#434444" }}>
                    <div className='mission-vision-container' style={{ width: "80%" }}>
                        <span className='title'>{t("PRIVACY_POLICY")}</span>
                        <span className='text'>{t("PRIVACY1")}</span>
                        <span className='text'>{t("PRIVACY2")}</span>
                        <span className='text'>{t("PRIVACY3")}</span>
                        <span style={{
                            marginTop: "3em",
                            marginBottom: "1em",
                            fontWeight: "500",
                            color: "#04A953"
                        }} className='text'>{t("PRIVACY4")}</span>
                        <span className='text'>{t("PRIVACY5")}</span>
                        <span style={{
                            marginTop: "3em",
                            marginBottom: "1em",
                            fontWeight: "500",
                            color: "#04A953"
                        }} className='text'>{t("PRIVACY6")}</span>
                        <span className='text'>{t("PRIVACY7")}</span>
                        <span className='text'>{t("PRIVACY8")}</span>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ marginTop: "2em", marginRight: "2em" }} src={right} width="30px" />
                            <span className='text'>{t("PRIVACY9")}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ marginTop: "2em", marginRight: "2em" }} src={right} width="30px" />
                            <span className='text'>{t("PRIVACY24")}</span>
                        </div>
                        <span style={{
                            marginTop: "3em",
                            marginBottom: "1em",
                            fontWeight: "500",
                            color: "#04A953"
                        }} className='text'>{t("PRIVACY10")}</span>
                        <span className='text'>{t("PRIVACY11")}</span>
                        <span style={{
                            marginTop: "3em",
                            marginBottom: "1em",
                            fontWeight: "500",
                            color: "#04A953"
                        }} className='text'>{t("PRIVACY12")}</span>
                        <span className='text'>{t("PRIVACY13")}</span>
                        <span style={{
                            marginTop: "3em",
                            marginBottom: "1em",
                            fontWeight: "500",
                            color: "#04A953"
                        }} className='text'>{t("PRIVACY14")}</span>
                        <span className='text'>{t("PRIVACY15")}</span>
                        <span style={{
                            marginTop: "3em",
                            marginBottom: "1em",
                            fontWeight: "500",
                            color: "#04A953"
                        }} className='text'>{t("PRIVACY16")}</span>
                        <span className='text'>{t("PRIVACY17")}</span>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ marginTop: "2em", marginRight: "2em" }} src={right} width="30px" />
                            <span className='text'>{t("PRIVACY18")}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ marginTop: "2em", marginRight: "2em" }} src={right} width="30px" />
                            <span className='text'>{t("PRIVACY19")}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ marginTop: "2em", marginRight: "2em" }} src={right} width="30px" />
                            <span className='text'>{t("PRIVACY20")}</span>
                        </div>
                        <span style={{
                            marginTop: "3em",
                            marginBottom: "1em",
                            fontWeight: "500",
                            color: "#04A953"
                        }} className='text'>{t("PRIVACY21")}</span>
                        <span className='text'>{t("PRIVACY22")}</span>
                    </div>
                </div>
            )
        }
    }

    return (
        <div id="vision&mission">
            {renderNav(navbarMode)}
            <div className='aboutus-navbar'>
                <div onClick={() => setNavbarMode("VISIONMISSION")} className={`aboutus-navbar-item ${navbarMode === "VISIONMISSION" ? "aboutus-navbar-item-active" : ""}`}>
                    <span className='text'>{t("VISION-MISSION-TITLE")}</span>
                </div>
                <div onClick={() => setNavbarMode("IDI")} className={`aboutus-navbar-item ${navbarMode === "IDI" ? "aboutus-navbar-item-active" : ""}`}>
                    <span className='text' onClick={() => setNavbarMode("IDI")}>{t("IDI")}</span>
                </div>
                <div onClick={() => setNavbarMode("PRIVACYPOLICY")} className={`aboutus-navbar-item ${navbarMode === "PRIVACYPOLICY" ? "aboutus-navbar-item-active" : ""}`}>
                    <span className='text' onClick={() => setNavbarMode("IDI")}>{t("PRIVACY_POLICY")}</span>
                </div>
                <div onClick={() => setNavbarMode("SUSTAINABILITY-POLICY")} className={`aboutus-navbar-item ${navbarMode === "SUSTAINABILITY-POLICY" ? "aboutus-navbar-item-active" : ""}`}>
                    <span className='text' onClick={() => setNavbarMode("SUSTAINABILITY-POLICY")}>{t("SUSTAINABILITY-POLICY")}</span>
                </div>
            </div>
        </div>
    )
}


export default withTranslation()(AboutUs);