import React from 'react';
import { withTranslation } from 'react-i18next';
import './About2.css';
import Card from './Card';
import agriculturainteligente from '../img/Redesenho_3/Icons_Carbon/agriculturainteligente.svg'
import biodiversidade from '../img/Redesenho_3/Icons_Carbon/biodiversidade.svg'
import creditoscarbono from '../img/Redesenho_3/Icons_Carbon/creditoscarbono.svg'

const About2 = (props) => {
    const { t } = props;


    return (

        <div className="about2-container">
            <div className="about2-title">
                <span>{t("CARBON-TITLE")}</span>
            </div>
            <div className="services-card-container">
                <div className="help-card">
                    <Card img={agriculturainteligente} title={t("CARBON-CARD1-TITLE")} subtitle={t("CARBON-CARD1-SUBTITLE")} />
                </div>
                <div className="help-card" >
                    <Card img={biodiversidade} title={t("CARBON-CARD2-TITLE")} subtitle={t("CARBON-CARD2-SUBTITLE")} />
                </div>
                <div className="help-card" >
                    <Card img={creditoscarbono} title={t("CARBON-CARD3-TITLE")} subtitle={t("CARBON-CARD3-SUBTITLE")} />
                </div>
            </div>

        </div>

    )
}


export default withTranslation()(About2);