import React, {useEffect, useState} from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap';
import './PopUpNewsletter.css';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const PopUpNewsletter = (props) => {
    const { t, showModal } = props;

    const handleClose = () => props.onClickCloseModal(false);
    
    return (
        
        <Modal centered show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body >
                <FontAwesomeIcon icon={faCheck} size="4x" />
                <span>{t("THANK-YOU")}</span>
            </Modal.Body>
        </Modal>
    )
}



export default withTranslation()(PopUpNewsletter);