import React from 'react';
import { withTranslation } from 'react-i18next';
import About from '../components/About';
import Contactform from '../components/Contactform';
import Footer2 from '../components/Footer2';
import MainServices from '../components/MainServices';
import Newsletter from '../components/Newsletter';
import Services from '../components/Services';
import './HomePage.css'
import AboutUs from '../components/AboutUs';

const AboutUsPage = (props) => {
    const { t } = props;

    return (
        <div id="home">
            <MainServices t={props.t} />
            <AboutUs t={props.t}/>
            <Newsletter t={props.t} />
            <div id="contactus">
                <Contactform t={props.t} />
            </div>
            <Footer2 t={props.t} />
        </div>
    )
}


export default withTranslation()(AboutUsPage);
