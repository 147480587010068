import React from 'react';
import { withTranslation } from 'react-i18next';
import './CardApps.css'

const CardApps = (props) => {

    const {img, title, subtitle  } = props;

    return (
        <div className="apps-card">
            <div className="apps-card-text">
                <img src={img} style={{marginBottom:"2em", width:"120px"}} />
                <span className="apps-card-title">{title}</span>
                <span className="apps-card-subtitle">{subtitle}</span>
            </div>
        </div>
    )
}


export default withTranslation()(CardApps);
