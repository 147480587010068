import logo from "../img/logo-black.svg";
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import './NavBar2.css'
import { Navbar, Container, Nav, NavDropdown, Button } from 'react-bootstrap';
import Language from "./Language";
import { UilArrowRight } from '@iconscout/react-unicons'
import { UilArrowDown } from '@iconscout/react-unicons'
import { HashLink } from "react-router-hash-link";

const NavBar2 = (props) => {
    const { t } = props;

    const [newsActive, setNewsActive] = useState(false);

    const scrollMe = (e, data) => {
        e.preventDefault();
        let t = document.getElementById(data);
        if (t) {

            window.scrollTo(0, t.offsetTop);
        }
        else {
            window.location = "/home";
            window.scrollTo(0, t.offsetTop);
        }
    };

    const scrollWithOffset = (el, type) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        var yOffset;
        if (type === "contact") {
            yOffset = window.innerWidth <= 960 ? -100 : -60;
        }
        else if (type === "our-pioneers") {
            yOffset = window.innerWidth <= 960 ? -200 : -180;
        }
        else {
            yOffset = window.innerWidth <= 960 ? -200 : -110;
        }
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    return (
        <div className="navbar-home">
            <Navbar style={{ margin: "auto", width: "84%" }} expand="lg" variant="dark">
                <Navbar.Brand href="/"><img className="navbar-img" src={logo} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav style={{ marginLeft: "auto" }}>
                        <div className="navbar-home-other-buttons">
                            <HashLink smooth scroll={el => scrollWithOffset(el)} exact to="/home"><a style={{ color: "white", fontWeight: "bold" }}>{t("NAVBAR-HOME")}</a></HashLink>
                        </div>
                        <div className="navbar-home-other-buttons">
                            <HashLink smooth scroll={el => scrollWithOffset(el)} exact to="/#about-us-home"><a style={{ color: "white", fontWeight: "bold" }}>{t("NAVBAR-ABOUTUS")}</a></HashLink>
                        </div>
                        <div className="navbar-home-other-buttons">
                            <HashLink smooth scroll={el => scrollWithOffset(el)} exact to="/home#services"><a style={{ color: "white", fontWeight: "bold" }}>{t("NAVBAR-SERVICES")}</a></HashLink>
                        </div>
                        <div className="navbar-home-other-buttons">
                            <HashLink smooth scroll={el => scrollWithOffset(el)} exact to="/apps"><a style={{ color: "white", fontWeight: "bold" }}>{t("NAVBAR-APPS")}</a></HashLink>
                        </div>
                        <div className="navbar-home-other-buttons">
                            <Nav.Link target="_blank" href="//blog.agroinsider.com"><a style={{ color: "white", fontWeight: "bold" }}>{t("NAVBAR-BLOG")}</a></Nav.Link>
                        </div>
                        <div className="navbar-home-other-buttons">
                            <HashLink smooth scroll={el => scrollWithOffset(el)} exact to="/clientarea"><a style={{ color: "white", fontWeight: "bold" }}>{t("CLIENT-AREA-TITLE")}</a></HashLink>
                        </div>
                        <div className="navbar-home-other-buttons">
                            <div onClick={e => setNewsActive(!newsActive)} style={{display:"flex"}}>
                                <Nav.Link><a style={{ color: "white", fontWeight: "bold" }}>{t("NEWS")}</a></Nav.Link>
                                {newsActive ? 
                                    (<UilArrowDown style={{cursor:"pointer"}} size="25" color="white" />)
                                    : 
                                    <UilArrowRight style={{cursor:"pointer"}} size="25" color="white" />
                                }
                            </div>
                            {newsActive && (
                                <div style={{animation:"fadeIn 1s"}}>
                                    <Nav.Link onClick={e => window.open("https://co2.agroinsider.com/", "_blank")}><a style={{ color: "white", fontWeight: "bold" }}>CO<sub>2</sub></a></Nav.Link>
                                    <Nav.Link onClick={e => window.open("https://forest.agroinsider.com/", "_blank")}><a style={{ color: "white", fontWeight: "bold" }}>{t("Forest")}</a></Nav.Link>
                                </div>
                            )
                            }
                        </div>
                        <div className="navbar-home-other-buttons">
                            <HashLink smooth scroll={el => scrollWithOffset(el)} exact to="/#contactus" onClick={e => scrollMe(e, "contactus")}><a style={{ color: "white", fontWeight: "bold" }}>{t("NAVBAR-CONTACTUS")}</a></HashLink>
                        </div>
                        <div className="navbar-home-other-buttons">
                            <Language />
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}


export default withTranslation()(NavBar2);
