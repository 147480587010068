import React from 'react';
import { withTranslation } from 'react-i18next';
import './About.css'
import sustentabilidade from '../img/sustentabilidade.png';
import carbono from '../img/mercadocarbono.png';
import aboutus from '../img/img_about_us.png'
import inovacao from '../img/inovacao.png'
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const About = (props) => {
    const { t } = props;

    const scrollMe = (e, data) => {
        e.preventDefault();
        let t = document.getElementById(data);
        if (t) {

            window.scrollTo(0, t.offsetTop);
        }
        else {
            window.location = "/home";
            window.scrollTo(0, t.offsetTop);
        }
    };

    const scrollWithOffset = (el, type) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        var yOffset;
        if (type === "contact") {
            yOffset = window.innerWidth <= 960 ? -100 : -60;
        }
        else if (type === "our-pioneers") {
            yOffset = window.innerWidth <= 960 ? -200 : -180;
        }
        else if (type === "about-us") {
            yOffset = window.innerWidth <= 960 ? -200 : -180;
        }
        else {
            yOffset = window.innerWidth <= 960 ? -200 : -110;
        }
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }
    
    return (
        <div id="about-us-home" className="about-container">
            <div style={{backgroundColor:"#EFF2F7"}}>
                <div className="about-text-image-container text-image-column about-text-image-container-paddings">
                        <div className="margin-left-img-carbon">
                            <img src={aboutus} />
                        </div>
                        <div className="about-text-container">
                            <div className="title">
                                <span>
                                {t("ABOUTUS-TITLE")}
                                </span>
                            </div>
                            <div className="text">
                                <span style={{fontWeight:"bold"}}>{t("WHO-ARE-WE")}</span>
                            </div>
                            <div className="text">
                                <span>{t("ABOUT-US-TEXT-ONE")}</span>
                            </div>
                            <div className="text">
                                <span>{t("ABOUT-US-TEXT-TWO")}</span>
                            </div>
                            <HashLink smooth scroll={el => scrollWithOffset(el)} exact to="/aboutus#vision&mission"  activeClassName="active-know-more" className="inactive-know-more-button">{t("ABOUTUS-KNOW-MORE")}</HashLink>
                        </div>
                </div>
            </div>

            <div className="about-text-image-container about-text-image-container-margins">
                <div className="about-text-container">
                    <div className="title">
                        <span>
                            {t("ABOUTUS-ENVIRONMENTAL-TITLE")}
                        </span>
                    </div>
                    <div className="text">
                        <span>{t("ABOUTUS-ENVIRONMENTAL-TEXT")}</span>
                    </div>
                </div>
                <div>
                    <img src={sustentabilidade} className="img-sustainable"/>
                </div>
            </div>

            <div style={{backgroundColor:"#EFF2F7"}}>
                <div className="about-text-image-container text-image-column about-text-image-container-paddings">
                        <div className="margin-left-img-carbon">
                            <img src={carbono} />
                        </div>
                        <div className="about-text-container">
                            <div className="title">
                                <span>
                                {t("ABOUTUS-CARBON-TITLE")}
                                </span>
                            </div>
                            <div className="text">
                                <span>{t("ABOUTUS-CARBON-TEXT-ONE")}</span>
                                <br />
                                <span>{t("ABOUTUS-CARBON-TEXT-TWO")}</span>
                                
                            </div>
                            
                            <NavLink to = '/about' activeClassName="active-know-more" className="inactive-know-more-button">{t("ABOUTUS-KNOW-MORE")}</NavLink>
                                
                            
                        </div>
                    
                </div>
            </div>

            <div className="about-text-image-container">
                <div className="about-text-container">
                    <div className="title">
                        <span>
                            {t("ABOUTUS-INNOVATION-TITLE")}
                        </span>
                    </div>
                    <div className="text">
                        <span>{t("ABOUTUS-INNOVATION-TEXT")}</span>
                    </div>

                        <NavLink to = '/apps' activeClassName="active-know-more" className="inactive-know-more-button">{t("ABOUTUS-DISCOVER-APPS")}</NavLink>
                    
                </div>
                <div>
                    <img src={inovacao} />
                </div>
            </div>
        </div>
    )   
}


export default withTranslation()(About);