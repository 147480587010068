import React from 'react';
import { withTranslation } from 'react-i18next';
import './Apps.css'
import features from '../img/Redesenho_3/Images_Apps/imgappfeatures.png'
import homeapp from '../img/Redesenho_3/Images_Apps/bgimgapp.png'
import areagestao from '../img/redesenho/images_icons_apps/Icons/some/areasgestao.svg'
import relatorios from '../img/redesenho/images_icons_apps/Icons/some/relatorios.svg'
import anomaliasidentificadas from '../img/redesenho/images_icons_apps/Icons/Cards/anomaliasidentificadas.svg'
import produtividade from '../img/redesenho/images_icons_apps/Icons/Cards/produtividade.svg'
import sistemasderega from '../img/redesenho/images_icons_apps/Icons/Cards/sistemasderega.svg'
import CardApps from './CardApps';
import { NavLink } from 'react-router-dom';

const Apps = (props) => {
    const { t } = props;

    return (
        <>
            <div className="all-apps-container">

                <div className="app-container by-column">
                    <div className="app-container-text">

                        <div className="app-container-text-title">
                            <span>{t("APPS-WEBAPPS")}</span>
                        </div>

                        <div className="app-container-text-subtitle">
                            <span>{t("APPS-WEBAPPS-TEXT")}</span>
                        </div>
                        <div >

                            <NavLink to="/clientarea" href='https://agromap.agroinsider.com/login' className="apps-get-started-button">{t("APPS-GET-STARTED")}</NavLink>
                        </div>

                    </div>
                    <div className="app-container-img">
                        <img src={homeapp} />
                    </div>
                </div>

                <div className="features-background">
                    <div className="app-container apps-by-column">
                        <div className="app-container-img">
                            <img src={features} />
                        </div>
                        <div  className="app-container-text">

                            <div className="app-container-text-title">
                                <span>{t("APPS-FEATURES")}</span>
                            </div>

                            <div className="app-container-text-reports-management-container">

                                <div className="app-report-img">
                                    <img src={relatorios} />
                                </div>

                                <div style={{width:"100%"}} className="app-container-text-reports-management-container-text">

                                    <div className="app-container-text-reports-management-container-text-title">
                                        <span>{t("APPS-FEATURES-ICON1-TITLE")}</span>
                                    </div>

                                    <div className="app-container-text-reports-management-container-text-subtitle">
                                        <span>{t("APPS-FEATURES-ICON1-TEXT")}</span>
                                    </div>

                                </div>
                            </div>


                            <div className="app-container-text-reports-management-container">

                                <div className="app-report-img">
                                    <img src={areagestao} />
                                </div>

                                <div style={{width:"100%"}} className="app-container-text-reports-management-container-text">

                                    <div className="app-container-text-reports-management-container-text-title">
                                        <span>{t("APPS-FEATURES-ICON2-TITLE")}</span>
                                    </div>

                                    <div className="app-container-text-reports-management-container-text-subtitle">
                                        <span>{t("APPS-FEATURES-ICON2-TEXT")}</span>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="app-container-help">
                    <div className="app-container-help-title">
                        <span>{t("APP-FEATURES-TITLE")}</span>
                    </div>

                    <div className="app-cards-container">
                        <div className="help-card-alert">
                            <CardApps img={sistemasderega} title={t("APPS-FEATURES-CARD1-TITLE")} subtitle={t("APPS-FEATURES-CARD1-SUBTITLE")} />
                        </div>

                        <div className="help-card-alert">
                            <CardApps img={produtividade} title={t("APPS-FEATURES-CARD2-TITLE")} subtitle={t("APPS-FEATURES-CARD2-SUBTITLE")} />
                        </div>

                        <div className="help-card-alert">
                            <CardApps img={anomaliasidentificadas} title={t("APPS-FEATURES-CARD3-TITLE")} subtitle={t("APPS-FEATURES-CARD3-SUBTITLE")} />
                        </div>
                    </div>
                </div>

            </div>

        </>


    )
}


export default withTranslation()(Apps);